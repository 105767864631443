<template>
  <div>
    <b-modal
      id="add-custom-order"
      ref="modal"
      header-class="headerModal"
      centered
      size="xl"
      :title="`${addedArticle.length != 0 || customDresses.length != 0 ? `${$t('CreateOrderMod')}` : `${$t('NothingIsAdded')}`
      }`"

      title-class="red"
      modal-class="dd"
      hide-footer
      @close="onCancel"
    >
      <b-form
        class="fixed-height-modal"
        @reset="onCancel"
      >
        <div
          class="existingClient"
          style="display:flex; flex-direction:row; width: 46%; justify-content: space-between;"
        >
          <b-button
            v-b-toggle.collapse-345
            class="m-1"
            variant="light"
          >
            <b-icon-arrows-expand />
          </b-button>
          <b-form-group
            style="width: 80%; padding-bottom: 10px;"
            :label="$t('SelectExistingClient')"
          >
            <div>
              <div class="black">
                <b-icon-search
                  v-if="searchClients == null"
                  class="search1"
                  style=" position: absolute; left: 5px; top: 10px"
                />
                <b-icon-x-circle
                  v-else-if="clientId != '00000000-0000-0000-0000-000000000000'"
                  class="search1"
                  style="position: absolute; margin-top: -6px; right: 40px; top: 17px; cursor: pointer"
                  @click="searchClients = null ,client= false, resetCustomerData()"
                />
                <input
                  v-model="searchClients"
                  autocomplete="off"
                  type="text"
                  name="search-box"
                  :placeholder="$t('ClientName')"
                  :style="getFirstNames.length > 0 && noSearch ? 'border-radius: 8px 8px 8px 8px' : 'border-radius: 8px 8px 8px 8px; padding-left: 25px;'"
                  @keyup="inputChanged"
                  @keydown.down="onArrowDown"
                  @keydown.up="onArrowUp"
                >
              </div>
              <div
                v-if="getFirstNames.length > 0 && noSearch"
                ref="scrollContainer"
                class="dropdrop"
                :style="getFirstNames.length > 7 ? 'height: 210px' : 'height: auto'"
              >
                <div
                  v-for="(result, index) in getFirstNames"
                  :key="index"
                  ref="options"
                  style="cursor: pointer"
                  class="search-item"
                  :class="{ 'is-active': index === arrowCounter }"
                  @click="searchByOn(result.clientId); noSearch = false"
                >
                  <p style="margin: 0">
                    {{ result.nameToDisplay }}
                  </p>
                </div>
              </div>
            </div>
          </b-form-group>
          <b-button
            v-if="client == false && clientId == '00000000-0000-0000-0000-000000000000'"
            type="button"
            variant="none"
            class="buttonSubmit"
            style="width: 290px; height: 40px; margin-top: 27px;"
            @click="addNewClient"
          >
            {{ $t(`Addclient`) }}
          </b-button>
          <b-button
            v-if="clientId != '00000000-0000-0000-0000-000000000000'"
            type="button"
            variant="none"
            class="buttonSubmit"
            style="width: 290px; height: 40px; margin-top: 27px;"
            @click="test(clientId)"
          >
            {{ $t(`GetMes`) }}
          </b-button>
        </div>

        <section
          v-if="client == true || clientId != '00000000-0000-0000-0000-000000000000'"
          class="custom-modal__wrapper"
          style="border-width: 3px"
        >
          <b-tabs>
            <b-tab
              :title="$t('PersonalInfo')"
              active
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("PersonalInfo") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div
                  class="hello"
                  style="padding-top: 20px"
                >
                  <b-form-group :label="$t('Salutation')">
                    <vue-select
                      v-model="$v.staticForm.ordererDto.salutation.$model"
                      :options="salutation"
                      required
                      :placeholder="$t('SelectSalutation')"
                      :state="validateState('salutation')"
                      aria-describedby="input-1-live-feedback"
                    />
                    <p
                      v-if="staticForm.ordererDto.salutation == null && isSubmitClicked == true"
                      style="color: red"
                    >
                      {{ $t('This_is_a_required_field') }}
                    </p>
                  </b-form-group>

                  <b-form-group :label="`${$t('FirstName')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.firstName.$model"
                      :state="validateState('firstName')"
                      placeholder="e.g. Michael"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('LastName')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.lastName.$model"
                      :state="validateState('lastName')"
                      placeholder="e.g. Goodman"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="hello">

                  <b-form-group :label="`${$t('Gender')}`">
                    <vue-select
                      v-model="$v.staticForm.ordererDto.gender.$model"
                      :options="[$t('Male'), $t('Female')]"
                      :placeholder="$t('SelectGender')"
                      required
                      :state="validateState('gender')"
                      aria-describedby="input-5-live-feedback"
                    />
                    <p
                      v-if="staticForm.ordererDto.gender == null && isSubmitClicked == true"
                      style="color: red"
                    >
                      {{ $t('This_is_a_required_field') }}
                    </p>
                  </b-form-group>

                  <b-form-group :label="`${$t('Email')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.email.$model"
                      :state="validateState('email')"
                      placeholder="e.g. example@digitbusiness.ch"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{
                        $t('Must_be_email_format')
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('PhoneNumber')}`">
                    <vue-phone-number-input
                      v-model="staticForm.ordererDto.telephoneNumber"
                      :default-country-code="staticForm.ordererDto.countryCode"
                      :preferred-countries="['CH', 'DE', 'AL']"
                      :error="isPhoneNumberValid === false"
                      @update="getFormattedNumber"
                    />
                  </b-form-group>
                </div>

                <b-form-group :label="`${$t('OrderType')}`">
                  <b-form-select
                    v-model="selectedOrderType"
                    :class="{ f: error.oType != '' }"
                    dropup
                    :options="transformOrderTypes"
                    required
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                        width: 67%;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                  <p
                    v-if="error.oType != ''"
                    style="margin-top: 0.25rem;
                          font-size: 0.875em;
                          color: #dc3545;"
                  >{{ $t(`${error.oType}`) }}</p>
                </b-form-group>
              </b-collapse>
            </b-tab>
            <b-tab
              :title="$t('BillingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("BillingAddress") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div
                  class="hello"
                  style="padding-top: 20px"
                >
                  <b-form-group :label="`${$t('Street')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.street"
                      placeholder="e.g. Rexhep Maja"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('HouseNumber')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.houseNumber"
                      type="number"
                      step="any"
                      min="0"
                      placeholder="e.g. 30"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback"> {{
                      $t('This_is_a_required_field_and_must_contains_numbers')
                    }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('DoorNumber')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.doorNumber"
                      type="number"
                      placeholder="e.g. 50"
                      step="any"
                      min="0"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_contains_numbers')
                    }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="hello">

                  <b-form-group :label="`${$t('PostalCode')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.postalCode"
                      min="0"
                      placeholder="e.g. 10000"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('City')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.city"
                      placeholder="e.g. Prishtinë"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('Country')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.country"
                      placeholder="e.g. Kosovë"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </b-collapse>
            </b-tab>
            <b-tab
              :title="$t('ShippingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("ShippingAddress") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div>
                  <div
                    class="hello"
                    style="padding-top: 20px"
                  >
                    <b-form-group :label="`${$t('Street')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.street"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Rexhep Maja"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback>{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('HouseNumber')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.houseNumber"
                        :disabled="pickUpInStore"
                        placeholder="e.g. 30"
                        type="number"
                        step="any"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('DoorNumber')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.doorNumber"
                        :disabled="pickUpInStore"
                        type="number"
                        placeholder="e.g. 50"
                        step="any"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="hello">

                    <b-form-group :label="`${$t('PostalCode')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.postalCode"
                        :disabled="pickUpInStore"
                        placeholder="e.g. 10000"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('City')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.city"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Prishtinë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('Country')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.country"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Kosovë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <span
                    class="duplicate-material"
                    @click="duplicateBillingAddress(), resetPickUpInStore()"
                  >
                    {{ $t('SameAsBillingAddress') }}
                  </span>
                </div>
                <p>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="pickUpInStore"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                    @input="resetShippingAddress()"
                  >
                    {{ $t('GetInStore') }}
                  </b-form-checkbox>
                </p>
              </b-collapse>
            </b-tab>
          </b-tabs>
        </section>

        <b-form-group />
        <div
          :style="addedArticle.length > 0 ? 'border: 3px solid lightgray; border-radius: 10px; padding: 15px; margin-top: 20px' : ''"
        >
          <StandardOrderItem
            v-for="(item, idx) in addedArticle"
            :key="idx"
            :articleid="idx"
            :show-measurements="showMeasurements"
            :client-id="clientId"
            :show-fitting-error="showFittingError"
            :dress="item"
            :body="body"
            :submit-clicked="isSubmitClicked"
            @DiscountValue="discountValue"
            @discount-type="discountTypeMethod"
            @sendIndexStandard="removeStandardDress"
            @selected-value-change="updateDefaultBodyMeasurementsStandardOrder(idx)"
            @taxRatee="taxRatee"
          />
        </div>
        <!-- Custom Dresses Section -->
        <CustomOrderItem
          v-for="(dress, idx) in customDresses"
          :key="idx"
          :dress="dress"
          :client-id="clientId"
          :idx="idx"
          :body="body"
          :show-dress-error="dress.showDressError"
          :selected-value="dress.defaultBodyMeasurements"
          :submit-clicked="isSubmitClicked"
          @discount-typee="discountTypeMethodd"
          @sendIndex="removeCustomDress"
          @selected-value-change="updateDefaultBodyMeasurements(idx)"
          @fitting="checkFittingDate"
          @taxRatee="taxRatee"
        />
        <button
          type="button"
          class="buttonSubmit"
          style="margin: 20px 0; width: 25%"
          @click="addNewCustomDress"
        >
          <p style="margin: 0">
            + {{ $t('AddCustomDress') }}
          </p>
        </button>
        <div style="margin-left:5px;margin-top: 10px;width:100%;border:3px solid lightgray;border-radius: 6px;padding:15px">
          <div style="display:flex;gap:10px">
            <b-form-group
              :label="$t('AddAccount')"
            >
              <vue-select
                v-model="accountId"
                :options="getAccountDetails"
                :reduce="(e) => e.accountId"
                label="name"
                :placeholder="$t('Select')"
                style="width: 100%;"
              />
            </b-form-group>
            <b-form-group
              :label="$t('SelectSalesPerson')"
            >
              <vue-select
                v-model="roleId"
                :options="getUsersMultipleRoles"
                required
                label="fullName"
                :reduce="(account) => account.userID"
                :placeholder="$t('SelectSalesPerson')"
                aria-describedby="input-1-live-feedback"
                style="width: 100%;"
              />
            </b-form-group>
          </div>
          <div style="display:flex;gap:10px;">
            <b-form-group
              :label="$t('InoviceRemark')"
              style="width:100%;padding-top:5px;"
            >
              <b-form-textarea
                v-model="invoiceRemark"
                :placeholder="$t('Notes')"
                style="width:100%"
              />
            </b-form-group>
            <b-form-group
              :label="$t('SellDate')"
              style="width:100%;padding-top:5px;"
            >
              <date-picker
                v-model="sellDate"
                :placeholder="$t('SelectD')"
                style="width:100%"
                type="date"
                format="YYYY-MM-DD"
                value-type="format"
              />
            </b-form-group>
          </div>
        </div>
        <div style="margin-left:5px;margin-top: 10px;width:100%;border:3px solid lightgray;border-radius: 6px;padding:15px">
          <b-form-group
            :label="$t('ShippingCost')"
            style="width:30%"
          >
            <div

              style="display:flex;flex-direction:column;gap:5px;"
            >
              <div style="display:flex;flex-direction:row;gap:5px;">
                <b-form-input
                  v-model.number="shippings.cost"
                  :placeholder="$t('Cost')"
                  type="number"
                />
                <b-form-select
                  v-model="shippings.currency"
                  dropup
                  :options="[{ value: null, text: 'None' }, ...currencies]"
                  aria-placeholder="Select order type"
                  aria-describedby="input-1-live-feedback"
                  style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                />
              </div>
              <p
                v-if="((shippings.cost === '' || shippings.cost < 0) && (shippings.accountId !== null || shippings.currency !== null || shippings.notes !== '' || shippings.paidAmount !== ''))"
                style="color:red"
              >{{ $t('FillData') }}</p>
              <b-form-group
                :label="$t('paidAmount')"
                style="width: 100%"
              />
              <b-form-input
                v-model.number="shippings.paidAmount"
                :placeholder="$t('paidAmount')"
                type="number"
              />
              <p
                v-if="((shippings.paidAmount === '' || shippings.paidAmount < 0) && (shippings.accountId !== null || shippings.currency !== null || shippings.notes !== '' || shippings.cost !== '' || shippings.cost < 0))"
                style="color:red"
              >{{ $t('FillData') }}</p>
              <b-form-group
                :label="$t('Notes')"
                style="width:100%"
              >
                <b-form-textarea
                  v-model="shippings.notes"
                  style="width:100%"
                  :placeholder="$t('Select')"
                  type="number"
                />
              </b-form-group>
              <p
                v-if="(shippings.notes === '' && (shippings.accountId !== null || shippings.currency !== null || shippings.cost !== '' || shippings.cost < 0 || shippings.paidAmount !== '' || shippings.paidAmount < 0))"
                style="color:red"
              >{{ $t('FillData') }}</p>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('SelectAccount')"
            style="margin-top:5px;margin-bottom:5px;width:30%"
          >
            <vue-select
              v-model="shippings.accountId"
              :options="getAccountDetails"
              required
              label="name"
              :reduce="(account) => account.accountId"
              :placeholder="$t('SelectAccount')"
              aria-describedby="input-1-live-feedback"
            />
            <p
              v-if="(shippings.accountId == null && (shippings.notes !== '' || shippings.currency !== null || shippings.cost !== '' || shippings.cost < 0 || shippings.paidAmount !== '' || shippings.paidAmount < 0))"
              style="color:red"
            >{{ $t('FillData') }}</p>
          </b-form-group>
        </div>
        <div style="display: flex;">
          <AddFile
            ref="addFile1"
            :folder="'OrderPhotos'"
            :label-text="$t('UploadSketch')"
            :file-data-type="'Scetch'"
            :file-name="createFileDataDto"
          />
          <AddFile
            ref="addFile2"
            :folder="'OrderPhotos'"
            :label-text="$t('UploadContract')"
            :file-data-type="'Contract'"
            :file-name="createFileDataDto"
          />
          <AddFile
            ref="addFile3"
            :folder="'OrderPhotos'"
            :label-text="$t('OtherDocuments')"
            :file-data-type="'OtherDocument'"
            :file-name="createFileDataDto"
          />
        </div>
        <div
          v-if="clienti == 'ValdrinSahiti'"
          style="display: flex;"
        >
          <b-form-group
            :label="$t('OrderNumber')"
            style="margin-top: 20px;width:100%"
          >
            <b-form-input
              v-model="orderNumber"
              style="width:100%;"
              :placeholder="$t('OrderName')"
            />
          </b-form-group>
        </div>
        <b-form-group
          :label="$t('Notes')"
          style="margin-top: 20px;"
        >
          <b-form-textarea
            id="textarea"
            v-model="description"
            :placeholder="$t('RequestedChangesByCustomer')"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{ $t('RequiredFields') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="price-container">
          <h5
            v-if="addedArticle.length || customDresses.length"
            class="price-text"
            style=" padding: 7px 0px; display:flex; flex-direction: column;text-align: left;width: 33%;
            height: 65px; font-size: 16px; font-weight: 500"
          >
            {{ $t("TotalP") }}:
            <p style="font-weight: 500; background: #F5F5F5; margin-top: 7px; padding: 10px 14px; border-radius: 8px; border: 1px solid #D0D5DD">
              {{ totalPrice }}
            </p>
          </h5>
          <h5
            v-if="addedArticle.length || customDresses.length"
            class="price-text"
            style=" padding: 7px 0px; margin-left: 15px; display: flex; flex-direction: column;text-align: left;width: 33%;
            height: 65px; font-size: 16px; font-weight: 500"
          >
            {{ $t("TotalPD") }}:
            <p style="font-weight: 500; background: #F5F5F5; margin-top: 7px; padding: 10px 14px; border-radius: 8px; border: 1px solid #D0D5DD">
              {{ totalPaidPrice }}
            </p>

          </h5>
          <h5
            v-if="addedArticle.length || customDresses.length"
            class="price-text"
            style=" padding: 7px 0px; margin-left: 15px;display: flex; flex-direction: column;text-align: left;width: 33%;
            height: 65px; font-size: 16px; font-weight: 500"
          >
            {{ $t("BillR") }}:
            <p style="font-weight: 500; background: #F5F5F5; margin-top: 7px; padding: 10px 14px; border-radius: 8px; border: 1px solid #D0D5DD">
              {{ totalPrice - totalPaidPrice }}
            </p>
          </h5>
        </div>
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            style="margin-top: 20px;"
            class="buttonSubmit"
            :disabled="loading"
            @click="onSubmit()"
          >
            <div
              v-if="loading"
              class="spinner-border spinner-border-sm"
            />
            {{ $t('Submit') }}
          </button>
          <b-button
            type="reset"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 20px; background: white; color: black; border-color: white;font-weight: 400;"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, email,
} from 'vuelidate/lib/validators';

import AWS from 'aws-sdk';
import DatePicker from 'vue2-datepicker';
import CustomOrderItem from './CustomOrderItem.vue'
import StandardOrderItem from './StandardOrderItem.vue'
import { AWSurl, client } from '../domainConfig';
import AddFile from './AddFile.vue'
import 'vue2-datepicker/index.css';


export default {
  components: {
    CustomOrderItem,
    StandardOrderItem,
    AddFile,
    DatePicker,
  },
  mixins: [validationMixin],

  // eslint-disable-next-line vue/require-prop-types
  props: ['materials', 'addedArticle'],
  id: {
    type: Object,
  },
  data() {
    return {
      sellDate: null,
      shippings: {
        cost: '',
        accountId: null,
        currency: null,
        notes: '',
        paidAmount: '',
      },
      currency: '€',
      currencies: ['€', '$', 'CHF'],
      body: [],
      clienti: client.clientName,
      client: false,
      discountType: '€',
      discountTypee: '€',
      showDiscountForm: false,
      loading: false,
      s3: null,
      roleId: null,
      invoiceRemark: null,
      bucketName: AWSurl.bucketName,
      fileData: [],
      showFittingError: false,
      arrowCounter: 0,
      enterPressed: false,
      searchClients: null,
      clientId: '00000000-0000-0000-0000-000000000000',
      noSearch: false,
      isPhoneNumberValid: null,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      selectedOrderType: null,
      pickUpInStore: false,
      partOptions: [],
      fitting: null,
      meterOptions: [],
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
      staticForm: {
        ordererDto: {
          salutation: null,
          firstName: '',
          lastName: '',
          email: '',
          gender: null,
          telephoneNumber: '',
          countryCode: '',
        },
        billingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
        shippingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
      },
      showMeasurements: false,
      description: '',
      orderNumber: '',
      createFileDataDto: [],
      isSubmitClicked: false,
      form: [],
      forms: [],
      customDresses: [],
      error: {
        oType: '',
        duplicateError: '',
      },
      formItem: [],
      sizeOptions: [
        {
          size: 'xs',
        },
        {
          size: 's',
        },
        {
          size: 'm',
        },
        {
          size: 'l',
        },
        {
          size: 'xl',
        },
      ],
      accountId: null,
      taxRate: 0,
    };
  },

  validations: {
    selectedOrderType: {
      required,
    },
    staticForm: {
      ordererDto: {
        salutation: {
          required,
        },
        firstName: {
          required,
          minLength: minLength(3),
        },
        lastName: {
          required,
          minLength: minLength(3),
        },
        email: {
          email,
        },
        gender: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters(['getClients',
      'getOrderTypes',
      'getMeasermentsById',
      'getFirstNames',
      'getTotalItemsForClients',
      'getClient',
      'getLanguage',
      'getAccountDetails',
      'getUsersMultipleRoles',
      'getInvoiceByOrderNumber']),
    totalPrice() {
      return this.addedArticle.reduce((total, item) => {
        let discountedPrice = null
        if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
          discountedPrice = item.itemi.price - item.itemi.discount;
        } else if (this.discountType === '%') {
          discountedPrice = item.itemi.price - (item.itemi.discount / 100) * item.itemi.price
        } else if (this.discountType == '' || this.discountType == null) {
          discountedPrice = item.itemi.price
        }
        return total + discountedPrice;
      }, 0) + this.customDresses.reduce((total, item) => {
        let discountedPrice = null
        if (this.discountTypee === '$' || this.discountTypee === '€' || this.discountTypee === 'CHF') {
          discountedPrice = item.price - item.discount;
        } else if (this.discountTypee === '%') {
          discountedPrice = item.price - (item.discount / 100) * item.price
        } else if (this.discountTypee == '' || this.discountTypee == null) {
          discountedPrice = item.price
        }
        return total + discountedPrice;
      }, 0);
    },
    totalPaidPrice() {
      return this.addedArticle.reduce((total, item) => { return total + parseFloat(item.paidPrice || 0) }, 0) + this.customDresses.reduce((total, item) => { return total + item.paidPrice }, 0);
    },
    isMaterialVariantIdEmpty() {
      return this.customDresses.some((item) => { return item.isEmpty });
    },
    transformOrderTypes() {
      const allOption = { value: null, text: `${this.$t('SelectOrderType')}`, orderType: 'null' };
      const orderTypes = this.getOrderTypes.map((order) => {
        return {
          value: order.orderType,
          text: order.orderType,
          orderType: order.orderType,
        }
      });
      return [allOption, ...orderTypes];
    },
  },
  watch: {
    form: {
      deep: true,
    },
    'shippings.cost': function (newCost) {
      if (newCost) {
        this.shippings.paidAmount = 0;
        this.shippings.currency = '€'
      }
      if (newCost < 0) {
        this.shippings.cost = 0
      }
    },
    'shippings.paidAmount': function (newCost) {
      if (newCost > this.shippings.cost) {
        this.shippings.paidAmount = 0;
      }
      if (newCost < 0) {
        this.shippings.paidAmount = 0
      }
    },
    addedArticle: {
      handler(newValue) {
        console.log(newValue);
        if (this.isSubmitClicked) {
          newValue.forEach((item) => {
            item.filledPickUpDate = item.pickUpDate !== '';
            const hasEmptyDateOfProbe = item.probeDates.some((probe) => { return probe.dateOfProbe === '' });
            console.log(hasEmptyDateOfProbe);
            this.showFittingError = hasEmptyDateOfProbe;
            // item.showLengthError = item.meter > 0.001;
          });
        }
        this.fillForms();
      },
      deep: true,
      immediate: true,
    },
    pickUpInStore(value) {
      if (value == true) {
        this.staticForm.shippingAddressDto.street = '';
        this.staticForm.shippingAddressDto.houseNumber = '';
        this.staticForm.shippingAddressDto.doorNumber = '';
        this.staticForm.shippingAddressDto.postalCode = '';
        this.staticForm.shippingAddressDto.city = '';
        this.staticForm.shippingAddressDto.country = '';
      }
    },
    selectedOrderType(value) {
      if (value !== null) {
        this.error.oType = ''
      }
    },
    measureItem() {
      this.form.measurementType = this.measureItem;
    },
    searchClients(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == null) {
          this.clearCustomMeasurements();
          this.removeLists();
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.firstNames(value);
          this.noSearch = true
        }
      }, 500);
    },
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
  },
  mounted() {
    if (this.getOrderTypes.length == 0) {
      this.loadOrderTypes()
    }
    // this.loadMeasermentsById()
    // console.log('eeee', this.getMeasermentsById)
    // this.loadMeasermentsById()
    this.usersByMultipleRoles({
      status: true,
      object: ['SalesPerson', 'OnlineSales', 'Designer'],
    })
  },

  methods: {
    ...mapActions([
      'usersByMultipleRoles', 'loadInvoiceByOrderNumber', 'resetCustomOrders', 'clearCustomMeasurements', 'loadOrderTypes', 'editClient', 'getClientById', 'getClientsPagination', 'getClientById', 'firstNames', 'resetSearchClients', 'loadMeasermentsById',
    ]),
    addNewClient() {
      this.client = true;
    },
    getFormattedNumber(n) {
      if (!n.number) {
        this.isPhoneNumberValid = null;
        this.staticForm.ordererDto.telephoneNumber = '';
        this.staticForm.ordererDto.countryCode = '';
        return;
      }

      if (n.isValid) {
        this.isPhoneNumberValid = true;
        this.staticForm.ordererDto.telephoneNumber = n.nationalNumber;
        this.staticForm.ordererDto.countryCode = n.countryCode;
      } else {
        this.isPhoneNumberValid = false;
        this.staticForm.ordererDto.telephoneNumber = '';
        this.staticForm.ordererDto.countryCode = '';
      }
    },
    discountTypeMethod(value) {
      this.discountType = value
    },
    discountTypeMethodd(value) {
      this.discountTypee = value
    },
    discountValue(value) {
      this.showDiscountForm = value
    },
    checkFittingDate(value) {
      this.fitting = value
    },
    taxRatee(value) {
      this.taxRate = value
    },
    // measureMethod() {
    //   this.loadMeasermentsById();
    // },
    updateDefaultBodyMeasurements(selectedIdx) {
      this.customDresses.forEach((dress, idx) => {
        dress.defaultBodyMeasurements = idx === selectedIdx;
      });
    },
    updateDefaultBodyMeasurementsStandardOrder(selectedIdx) {
      this.addedArticle.forEach((dress, idx) => {
        dress.defaultBodyMeasurements = idx === selectedIdx;
      });
    },
    async test(value) {
      // console.log('1', value)
      // this.$emit('test', value)
      this.showMeasurements = true;
      await this.loadMeasermentsById(value);
      // if (this.customDresses.length < 1) { this.addNewCustomDress() }
      this.customDresses.forEach((dress) => {
        dress.sizesDto = this.getMeasermentsById
      });
      this.addedArticle.forEach((dress) => {
        dress.bodyMeasurements = this.getMeasermentsById
      });
    },
    async searchByOn(value) {
      this.noSearch = false
      const clientData = await this.getClientById({
        clientId: value,
        successCallback: () => {
        },
      })
      this.searchClients = `${clientData.firstName} ${clientData.lastName}`
      this.clientId = value
      this.staticForm.ordererDto.salutation = clientData.salutation
      this.staticForm.ordererDto.firstName = clientData.firstName
      this.staticForm.ordererDto.lastName = clientData.lastName
      this.staticForm.ordererDto.gender = clientData.gender
      this.staticForm.ordererDto.email = clientData.email
      this.staticForm.ordererDto.telephoneNumber = clientData.telephoneNumber
      this.staticForm.ordererDto.countryCode = clientData.countryCode
      this.staticForm.ordererDto.taxRate = clientData.taxRate
      this.staticForm.billingAddressDto.street = clientData.billingAddressDto.street
      this.staticForm.billingAddressDto.houseNumber = clientData.billingAddressDto.houseNumber
      this.staticForm.billingAddressDto.doorNumber = clientData.billingAddressDto.doorNumber
      this.staticForm.billingAddressDto.postalCode = clientData.billingAddressDto.postalCode
      this.staticForm.billingAddressDto.city = clientData.billingAddressDto.city
      this.staticForm.billingAddressDto.country = clientData.billingAddressDto.country
      this.staticForm.shippingAddressDto.street = clientData.shippingAddressDto.street
      this.staticForm.shippingAddressDto.houseNumber = clientData.shippingAddressDto.houseNumber
      this.staticForm.shippingAddressDto.doorNumber = clientData.shippingAddressDto.doorNumber
      this.staticForm.shippingAddressDto.postalCode = clientData.shippingAddressDto.postalCode
      this.staticForm.shippingAddressDto.city = clientData.shippingAddressDto.city
      this.staticForm.shippingAddressDto.country = clientData.shippingAddressDto.country
      this.body = clientData
    },
    removeLists() {
      this.noSearch = false;
      this.resetSearchClients();
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFirstNames.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFirstNames[this.arrowCounter].clientId)
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    fillForms() {
      this.forms = []
      for (let i = 0; i < this.addedArticle.length; i++) {
        this.forms.push(this.addedArticle[i].materials.productSingleMaterialVariantDtos ? this.addedArticle[i].materials.productSingleMaterialVariantDtos : [])
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.staticForm.ordererDto[name];
      return $dirty ? !$error : null;
    },
    // validateState2(name) {
    //   const { $dirty, $error } = this.$v.staticForm.billingAddressDto[name];
    //   return $dirty ? !$error : null;
    // },
    // validateState3(name) {
    //   const { $dirty, $error } = this.$v.staticForm.shippingAddressDto[name];
    //   return $dirty ? !$error : null;
    // },
    validateState4(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },

    removeCustomDress(idx) {
      this.customDresses.splice(idx, 1)
      this.$emit('decrementCounter')
    },
    removeStandardDress(key) {
      this.addedArticle.splice(key, 1)
      this.$emit('decrementCounter')
    },
    resetPickUpInStore() {
      this.pickUpInStore = false
    },
    duplicateBillingAddress() {
      this.staticForm.shippingAddressDto.street = this.staticForm.billingAddressDto.street;
      this.staticForm.shippingAddressDto.houseNumber = this.staticForm.billingAddressDto.houseNumber;
      this.staticForm.shippingAddressDto.doorNumber = this.staticForm.billingAddressDto.doorNumber;
      this.staticForm.shippingAddressDto.postalCode = this.staticForm.billingAddressDto.postalCode;
      this.staticForm.shippingAddressDto.city = this.staticForm.billingAddressDto.city;
      this.staticForm.shippingAddressDto.country = this.staticForm.billingAddressDto.country;
    },
    addNewRow(index) {
      if (this.forms[index].length > 0) {
        this.forms[index].push({
          materialVariantId: '',
          measurementType: 'Meter',
          name: '',
          length: 0,
          quantity: 0,
        });
      }
    },
    addNewCustomDress() {
      // this.clearCustomMeasurements()
      this.customDresses.push({
        showDressError: false,
        paidPrice: 0,
        price: 0,
        probeDates: [],
        defaultBodyMeasurements: false,
        title: `Custom Dress ${this.customDresses.length + 1}`,
        formItem: [],
        pickUpDate: '',
        filledPickUpDate: true,
        showLengthError: false,
        standardSize: '',
        eArticle: false,
        existingSizes: false,
        isEmpty: false,
        showFrontBody: true,
        sizesDto: {
          thorax: '',
          aroundBust: '',
          aroundUnderBust: '',
          waist: '',
          aroundHips: '',
          aroundLeg: '',
          aroundKnee: '',
          ankle: '',
          breastReduction: '',
          interBust: '',
          frontBody: '',
          chestDistance: '',
          length1: '',
          length2: '',
          length3: '',
          length4: '',
          aroundSleeve: '',
          elbow: '',
          wrist: '',
          aroundNeck: '',
          backBody: '',
          shoulders: '',
          back: '',
          declineOfTheHips: '',
          sleeveLength: '',
        },
        duplicateMV: false,
      })
      this.$emit('incrementCounter')
    },

    async onSubmit() {
      try {
        this.isSubmitClicked = true;
        this.$v.staticForm.ordererDto.$touch();

        // Check if pickup in store is false (meaning delivery is required)
        if (this.pickUpInStore === false) {
          if (
            this.$v.staticForm.ordererDto.$anyError
        || this.selectedOrderType == null
        || (this.staticForm.ordererDto.telephoneNumber && this.isPhoneNumberValid === false)
          ) {
            if (this.selectedOrderType == null) {
              this.error.oType = 'This_is_a_required_field';
            }
            if (this.staticForm.ordererDto.telephoneNumber && this.isPhoneNumberValid == null) {
              this.isPhoneNumberValid = false;
            }
            return;
          }
        }

        // Check if pickup in store is true (no delivery required)
        if (this.pickUpInStore === true) {
          if (
            this.$v.staticForm.ordererDto.$anyError
        || this.selectedOrderType == null
        || (this.staticForm.ordererDto.telephoneNumber && this.isPhoneNumberValid === false)
          ) {
            if (this.selectedOrderType == null) {
              this.error.oType = 'This_is_a_required_field';
            }
            if (this.staticForm.ordererDto.telephoneNumber && this.isPhoneNumberValid == null) {
              this.isPhoneNumberValid = false;
            }
            return;
          }
        }
        const hasProbeDate = this.addedArticle.some((item) => { return item.probeDates.length > 0 });
        console.log(hasProbeDate);

        if (hasProbeDate && this.addedArticle.some((item) => { return item.probeDates.some((probe) => { return probe.dateOfProbe === '' }) })) {
          this.showFittingError = true;
          return;
        }
        if (this.fitting) {
          return;
        }
        if (this.forms.length == 0 && this.customDresses.length == 0) return;
        let materialVariants = []
        const object2 = []
        // standard dresses
        // eslint-disable-next-line no-restricted-syntax
        for (let i = 0; i < this.forms.length; i++) {
          materialVariants = []
          // eslint-disable-next-line no-restricted-syntax
          for (const item of this.forms[i]) {
            materialVariants.push({
              name: item.name, measurementType: item.measurementType, materialVariantId: item.materialVariantId, length: item.length, quantity: item.quantity, sellPrice: item.name.sellPrice,
            })
          }

          if (this.addedArticle.length > 0) {
            if (this.addedArticle[i].pickUpDate == '') {
              this.addedArticle[i].filledPickUpDate = false;
              console.log('hini')
            }
          }

          if (this.addedArticle[i].showLengthError == true) {
            return;
          }
          let discountedPrice = null
          if (this.discountType == '$' || this.discountType == '€' || this.discountType == 'CHF') {
            discountedPrice = this.addedArticle[i].itemi.price - this.addedArticle[i].itemi.discount;
          } else if (this.discountType == '%') {
            discountedPrice = this.addedArticle[i].itemi.price - (this.addedArticle[i].itemi.discount / 100) * this.addedArticle[i].itemi.price
          } else if (this.discountType == '' || this.discountType == null) {
            discountedPrice = this.addedArticle[i].itemi.price
          }
          if (this.discountedPrice != this.addedArticle[i].itemi.price) {
            if (this.addedArticle[i].twentyPrinciples == null) {
              object2.push({
                twentyPrinciples: null,
                size: this.addedArticle[i].itemi.size,
                articleNumber: this.addedArticle[i].articleName,
                pickUpDate: this.addedArticle[i].pickUpDate,
                probeDates: this.addedArticle[i].probeDates,
                materialVariantDtos: materialVariants,
                paidAmount: this.addedArticle[i].paidPrice,
                price: this.addedArticle[i].itemi.price,
                paidNotes: this.addedArticle[i].itemi.priceNote,
                currency: this.addedArticle[i].itemi.currency,
                discount: this.addedArticle[i].itemi.price - discountedPrice,
                taxRate: this.taxRate,
              })
            } else {
              object2.push({
                bodyMeasurements: this.addedArticle[i].bodyMeasurements,
                articleNumber: this.addedArticle[i].articleName,
                pickUpDate: this.addedArticle[i].pickUpDate,
                probeDates: this.addedArticle[i].probeDates,
                defaultBodyMeasurements: this.addedArticle[i].defaultBodyMeasurements,
                materialVariantDtos: materialVariants,
                paidAmount: this.addedArticle[i].paidPrice,
                price: this.addedArticle[i].itemi.price,
                paidNotes: this.addedArticle[i].itemi.priceNote,
                currency: this.addedArticle[i].itemi.currency,
                discount: this.addedArticle[i].itemi.price - discountedPrice,
                taxRate: this.taxRate,
              })
            }
          } else if (this.discountedPrice == this.addedArticle[i].itemi.price) {
            if (this.addedArticle[i].twentyPrinciples == null) {
              object2.push({
                twentyPrinciples: null,
                size: this.addedArticle[i].itemi.size,
                articleNumber: this.addedArticle[i].articleName,
                pickUpDate: this.addedArticle[i].pickUpDate,
                probeDates: this.addedArticle[i].probeDates,
                materialVariantDtos: materialVariants,
                paidAmount: this.addedArticle[i].paidPrice,
                price: this.addedArticle[i].itemi.price,
                paidNotes: this.addedArticle[i].itemi.priceNote,
                currency: this.addedArticle[i].itemi.currency,
                discount: this.addedArticle[i].itemi.price - discountedPrice,
                taxRate: this.taxRate,
              })
            } else {
              object2.push({
                bodyMeasurements: this.addedArticle[i].bodyMeasurements,
                articleNumber: this.addedArticle[i].articleName,
                pickUpDate: this.addedArticle[i].pickUpDate,
                probeDates: this.addedArticle[i].probeDates,
                defaultBodyMeasurements: this.addedArticle[i].defaultBodyMeasurements,
                materialVariantDtos: materialVariants,
                paidAmount: this.addedArticle[i].paidPrice,
                price: this.addedArticle[i].itemi.price,
                paidNotes: this.addedArticle[i].itemi.priceNote,
                currency: this.addedArticle[i].itemi.currency,
                discount: this.addedArticle[i].itemi.price - discountedPrice,
                taxRate: this.taxRate,
              })
            }
          }
        }
        // end standard dresses

        // start custom  dresses
        for (let i = 0; i < this.customDresses.length; i++) {
          if (!this.customDresses[i].formItem || this.customDresses[i].formItem.length === 0 || this.customDresses[i].isEmpty == true) {
            this.customDresses[i].isEmpty = true;
            return;
          }
          materialVariants = []
          // eslint-disable-next-line no-restricted-syntax
          for (const item of this.customDresses[i].formItem) {
            if (typeof item.name === 'object') {
              materialVariants.push({
                name: item.name.materialVariantName, measurementType: item.measurementType, materialVariantId: item.name.materialVariantId, length: item.length, quantity: item.quantity, sellPrice: item.name.sellPrice,
              })
            } else {
              materialVariants.push({
                name: item.name, measurementType: item.measurementType, materialVariantId: item.materialVariantId, length: item.length, quantity: item.quantity, sellPrice: item.name.sellPrice,
              })
            }
          }
          if (this.customDresses.length > 0) {
            if (this.customDresses[i].pickUpDate == '') {
              this.customDresses[i].filledPickUpDate = false;
            }
          }

          if (this.customDresses[i].existingSizes == true) {
            this.customDresses[i].sizesDto = null
          } else {
            this.customDresses[i].standardSize = null
          }
          if (this.customDresses[i].showLengthError == true) {
            return;
          }
          if (this.customDresses[i].showDressError == true) {
            return;
          }
          // const discountedPrice = this.customDresses[i].price - this.customDresses[i].discount;
          let discountedPrice = null
          if (this.discountTypee === '$' || this.discountTypee === '€' || this.discountTypee === 'CHF') {
            discountedPrice = this.customDresses[i].price - this.customDresses[i].discount;
          } else if (this.discountTypee === '%') {
            discountedPrice = this.customDresses[i].price - (this.customDresses[i].discount / 100) * this.customDresses[i].price
          } else if (this.discountTypee == '' || this.discountTypee == null) {
            discountedPrice = this.customDresses[i].price
          }
          if (this.discountedPrice != this.customDresses[i].price) {
            object2.push({
              bodyMeasurements: this.customDresses[i].sizesDto,
              size: this.customDresses[i].standardSize,
              articleNumber: this.customDresses[i].title,
              pickUpDate: this.customDresses[i].pickUpDate,
              materialVariantDtos: materialVariants,
              probeDates: this.customDresses[i].probeDates,
              defaultBodyMeasurements: this.customDresses[i].defaultBodyMeasurements,
              paidAmount: this.customDresses[i].paidPrice,
              price: this.customDresses[i].price,
              paidNotes: this.customDresses[i].priceNote,
              currency: this.customDresses[i].currency,
              discount: this.customDresses[i].price - discountedPrice,
              taxRate: this.taxRate,
            })
          } else if (this.discountedPrice == this.customDresses[i].price) {
            object2.push({
              bodyMeasurements: this.customDresses[i].sizesDto,
              size: this.customDresses[i].standardSize,
              articleNumber: this.customDresses[i].title,
              pickUpDate: this.customDresses[i].pickUpDate,
              materialVariantDtos: materialVariants,
              probeDates: this.customDresses[i].probeDates,
              defaultBodyMeasurements: this.customDresses[i].defaultBodyMeasurements,
              paidAmount: this.customDresses[i].paidPrice,
              price: this.customDresses[i].price,
              paidNotes: this.customDresses[i].priceNote,
              currency: this.customDresses[i].currency,
              discount: this.customDresses[i].price - discountedPrice,
              taxRate: this.taxRate,
            })
          }
        }
        // end custom dresses
        if (this.customDresses.length > 0) {
          for (let i = 0; i < this.customDresses.length; i++) {
            if (this.customDresses[i].duplicateMV == true || this.customDresses[i].pickUpDate == '') {
              return;
            }
          }
        }

        if (this.addedArticle.length > 0) {
          for (let i = 0; i < this.addedArticle.length; i++) {
            if (this.addedArticle[i].pickUpDate == '') {
              return;
            }
          }
        }

        // Check for shippings validity
        if (
          (this.shippings.cost < 0 && (this.shippings.accountId === null || this.shippings.currency === null || this.shippings.notes === '' || this.shippings.paidAmount === ''))
      || (this.shippings.accountId !== null && (this.shippings.cost === '' || this.shippings.currency === null || this.shippings.notes === '' || this.shippings.paidAmount === ''))
      || (this.shippings.currency !== null && (this.shippings.cost === '' || this.shippings.accountId === null || this.shippings.notes === '' || this.shippings.paidAmount === ''))
      || (this.shippings.notes !== '' && (this.shippings.cost === '' || this.shippings.accountId === null || this.shippings.currency === null || this.shippings.paidAmount === ''))
      || (this.shippings.paidAmount < 0 && (this.shippings.cost === '' || this.shippings.accountId === null || this.shippings.currency === null || this.shippings.notes === ''))
        ) {
          return;
        }

        // Instead of setting shippings to null, use a flag to indicate if it's empty
        if (
          this.shippings.cost === ''
      && this.shippings.accountId === null
      && this.shippings.currency === null
      && this.shippings.notes === ''
      && this.shippings.paidAmount === ''
        ) {
          this.shippings.isEmpty = true;
        } else {
          this.shippings.isEmpty = false;
        }
        this.loading = true;
        // eslint-disable-next-line no-restricted-syntax
        await this.$refs.addFile1.uploadFile();
        await this.$refs.addFile2.uploadFile();
        await this.$refs.addFile3.uploadFile();
        this.$emit('createOrder', {
          ordererDto: this.staticForm.ordererDto,
          billingAddressDto: this.staticForm.billingAddressDto,
          pickUpInStore: this.pickUpInStore,
          orderType: this.selectedOrderType,
          clientId: this.clientId,
          shippingAddressDto: this.staticForm.shippingAddressDto,
          orderItemInStoreDtos: object2,
          description: this.description,
          orderNumber: this.orderNumber != '' ? this.orderNumber : null,
          createFileDataDto: this.createFileDataDto,
          shippingCostRequestDto: this.shippings.isEmpty ? {} : this.shippings,
          accountId: this.accountId,
          salesUserId: this.roleId,
          invoiceRemark: this.invoiceRemark,
          sellDate: this.sellDate,
        });
        this.loading = false;
        this.discountType = '€';
        this.discountTypee = '€';
        this.$refs.modal.hide();
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
        setTimeout(() => {
        }, 10);
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      } finally {
        this.loading = false;
        this.createFileDataDto = [];
      }
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        this.resetForm();
        this.materials = [];
      }, 10);
    },
    resetShippingAddress() {
      // if (this.pickUpInStore == true) {
      // this.$v.staticForm.shippingAddressDto.$anyError = true;
      //   this.error.oType = ''
      this.$v.staticForm.shippingAddressDto.$reset()
      // }
      // this.$refs.modal.hide();
    },
    resetForm() {
      this.orderNumber = '';
      this.client = false;
      this.discountType = '€';
      this.discountTypee = '€';
      // Reset our form values
      this.addedArticle = []
      this.customDresses = []
      this.forms = []
      this.body = null
      this.searchClients = null
      this.clientId = '00000000-0000-0000-0000-000000000000'
      this.error.oType = ''
      this.resetCustomerData();
      this.resetCustomOrders();
      this.isSubmitClicked = false;
      this.description = '';
      this.clearCustomMeasurements()
      this.accountId = null
      this.roleId = null
      this.invoiceRemark = null
      this.sellDate = null
      this.shippings.cost = ''
      this.shippings.currency = null
      this.shippings.accountId = null
      this.shippings.notes = ''
      this.shippings.paidAmount = ''
    },
    resetCustomerData() {
      this.staticForm.ordererDto.salutation = null;
      this.staticForm.ordererDto.firstName = '';
      this.staticForm.ordererDto.lastName = '';
      this.staticForm.ordererDto.email = '';
      this.staticForm.ordererDto.gender = null;
      this.staticForm.ordererDto.telephoneNumber = '';

      this.staticForm.billingAddressDto.street = '';
      this.staticForm.billingAddressDto.houseNumber = '';
      this.staticForm.billingAddressDto.doorNumber = '';
      this.staticForm.billingAddressDto.postalCode = '';
      this.staticForm.billingAddressDto.city = '';
      this.staticForm.billingAddressDto.country = '';

      this.staticForm.shippingAddressDto.street = '';
      this.staticForm.shippingAddressDto.houseNumber = '';
      this.staticForm.shippingAddressDto.doorNumber = '';
      this.staticForm.shippingAddressDto.postalCode = '';
      this.staticForm.shippingAddressDto.city = '';
      this.staticForm.shippingAddressDto.country = '';

      this.pickUpInStore = false;
      this.selectedOrderType = null;
      this.isPhoneNumberValid = null;
      this.searchClients = null
      this.clientId = '00000000-0000-0000-0000-000000000000'
    },
  },
};
</script>

<style lang="scss" scoped>

.modal-header{
  font-size: 25px !important;
}

.fixed-height-modal[data-v-094d39fb] {
    height: 100%;
    overflow-y: auto;
    padding-right: 1rem;
}
textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 1090px;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    width: 49%;
}
.price-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.hello{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1%
}
.price-text {
  margin-left: 0px;
  font-size: 18px;
  // font-weight: bold;
  color: black;
  border-radius: 10px;
}
.search1 {
    padding: 0px;
  }
.black {
  position: relative;
}
.black input {
  height: 38px;
}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
z-index: 2;
box-shadow: none;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}


.headline {
  padding: 10px 10px;
  background-color: white;
  color: black;
  font-weight: bold;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: 100%;
  overflow-y: auto;
  padding-right: 1rem;
}

.customDress-container {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.customDress-container:last-child {
  margin-bottom: 20px;
}

.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}

.front-body {
  position: relative;

  img {
    width: 100%;
  }

  .toraks {
    display: flex;
    position: absolute;
    top: 106px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiGjoksit {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiNenGjoksit {
    display: flex;
    position: absolute;
    top: 165px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .beli {
    display: flex;
    position: absolute;
    top: 200px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .vithet {
    display: flex;
    position: absolute;
    top: 263px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiKembes {
    display: flex;
    position: absolute;
    bottom: 222px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiGjurit {
    display: flex;
    position: absolute;
    bottom: 148px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .zoguKembeve {
    display: flex;
    position: absolute;
    bottom: 38px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .reniaGjoksit {
    display: flex;
    position: absolute;
    top: 85px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .nderGjoks {
    display: flex;
    position: absolute;
    top: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .trupiPara {
    display: flex;
    position: absolute;
    top: 200px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .distancaGjoksit {
    display: flex;
    position: absolute;
    top: 128px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia1 {
    display: flex;
    position: absolute;
    bottom: 222px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia2 {
    display: flex;
    position: absolute;
    bottom: 127px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia3 {
    display: flex;
    position: absolute;
    bottom: 68px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesia4 {
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}

.btn-con {
  display: flex;

  button {
    border: none;
    background: #FF274F;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}

.back-body {
  position: relative;

  img {
    width: 100%;
  }

  .rrethiMenges {
    display: flex;
    position: absolute;
    top: 126px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .brryli {
    display: flex;
    position: absolute;
    top: 156px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .kyçi {
    display: flex;
    position: absolute;
    top: 192px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .rrethiQafes {
    display: flex;
    position: absolute;
    top: 60px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .trupiPrapa {
    display: flex;
    position: absolute;
    top: 202px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .supet {
    display: flex;
    position: absolute;
    top: 97px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .shpina {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .reniaVitheve {
    display: flex;
    position: absolute;
    bottom: 253px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }

  .gjatesiaMenges {
    display: flex;
    position: absolute;
    top: 95px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}

@media only screen and (max-width: 1200px) {
  textarea.form-control[data-v-094d39fb] {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 745px;
  }
  .existingClient{
    width: 65% !important;
  }
  .front-body {
    position: relative;

    .toraks {
      top: 65px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .rrethiGjoksit {
      position: absolute;
      top: 95px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .rrethiNenGjoksit {
      position: absolute;
      top: 125px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .beli {
      position: absolute;
      top: 155px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .vithet {
      position: absolute;
      top: 249px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .rrethiKembes {
      position: absolute;
      bottom: 65px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .rrethiGjurit {
      position: absolute;
      bottom: 35px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .zoguKembeve {
      position: absolute;
      bottom: 5px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .reniaGjoksit {
      position: absolute;
      top: 65px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .nderGjoks {
      position: absolute;
      top: 125px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .trupiPara {
      position: absolute;
      top: 155px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .distancaGjoksit {
      position: absolute;
      top: 95px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .gjatesia1 {
      position: absolute;
      bottom: 95px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .gjatesia2 {
      position: absolute;
      bottom: 65px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .gjatesia3 {
      position: absolute;
      bottom: 35px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .gjatesia4 {
      position: absolute;
      bottom: 5px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }
  }

  .btn-con {
    display: flex;

    button {
      border: none;
      background-color: #6c757d;
      ;
      color: $digit-white;
      border-radius: 0.25rem;
      padding: 3px 12px;
    }
  }

  .back-body {
    position: relative;

    img {
      width: 100%;
    }

    .rrethiMenges {
      position: absolute;
      top: 126px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .brryli {
      position: absolute;
      top: 156px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .kyçi {
      position: absolute;
      top: 192px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .rrethiQafes {
      position: absolute;
      top: 70px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .trupiPrapa {
      position: absolute;
      top: 215px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .supet {
      position: absolute;
      top: 97px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .shpina {
      position: absolute;
      top: 70px;
      left: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .reniaVitheve {
      position: absolute;
      bottom: 100px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .gjatesiaMenges {
      position: absolute;
      top: 98px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }
  }
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: $base-color ;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

@media only screen and (max-width: 800px) {
  .front-body {
    .vithet {
      position: absolute;
      top: 195px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }
  }
}
</style>
