<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div class="standard-order-con">
    <div style="display: flex; justify-content: space-between;">
      <div style="display: inline-flex; align-items: baseline">
        <h4
          v-if="!editArticleNumber"
          :key="articleid"
          class="headline"
        >
          {{ dress.articleName }}
        </h4>
        <b-input
          v-else
          :key="articleid"
          v-model="dress.articleName"
          style="width: 100% !important;"
          type="text"
        />

        <div
          class="inside-actions"
          @click="editArticleNumber = !editArticleNumber"
        >
          <b-icon-pencil v-if="editArticleNumber == false" />
          <b-icon-check2 v-if="editArticleNumber == true" />
        </div>
        <div
          class="inside-actions"
          @click="removeStandardDress(articleNumberId)"
        >
          <b-icon-trash />
        </div>
      </div>
    </div>
    <div style="display:flex">
      <b-form-checkbox
        v-model="dress.twentyPrinciples"
        :value="true"
        :unchecked-value="null"
        style="margin-right: 10px; border-radius: 50%;"
      >{{ $t('CustomSize') }}</b-form-checkbox>

      <b-form-checkbox
        v-if="dress.twentyPrinciples"
        :id="idx"
        :key="idx"
        v-model="selectedValue"
        name="plain-inline"
        :value="true"
        :unchecked-value="false"
        @change="updateSelectedValue"
      > {{ $t('SetDefaultSize') }}</b-form-checkbox>
    </div>
    <b-button
      v-b-toggle="`collapse-standard`"
      class="m-1"
      variant="light"
    >
      <b-icon-arrows-expand />
    </b-button>
    <div
      v-if="dress.twentyPrinciples != null && client == 'ValdrinSahiti'"
      class="skica-container"
    >
      <b-collapse
        :id="`collapse-${idx}`"
        visible
      >
        <div
          class="front-body"
        >

          <div class="betweenCol">
            <div class="input">
              <span>{{ $t('toraks') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.thorax"
                :placeholder="$t('')"
                class="toraks1"
              />
            </div>
            <div class="input">
              <span>{{ $t('Bust') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.aroundBust"
                :placeholder="$t('')"
                class="rrethiGjoksit1"
              />
            </div>
            <div class="input">
              <span>{{ $t('UnderBust') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.aroundUnderBust"
                :placeholder="$t('')"
                class="rrethiNenGjoksit1"
              />
            </div>
            <div class="input">
              <span>{{ $t('Abdomen') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.waist"
                :placeholder="$t('')"
                class="beli1"
              />
            </div>
            <div class="input">
              <span>{{ $t('Hips') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.aroundHips"
                :placeholder="$t('')"
                class="vithet1"
              />
            </div>
            <div class="input">
              <span>{{ $t('BustReduction') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.aroundLeg"
                :placeholder="$t('')"
                class="rrethiKembes1"
              />
            </div>
            <div class="input">
              <span>{{ $t('UnderBustReduction') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.aroundKnee"
                :placeholder="$t('')"
                class="rrethiGjurit1"
              />
            </div>
            <div class="input">
              <span>{{ $t('FrontBody') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.ankle"
                :placeholder="$t('')"
                class="zoguKembeve1"
              />
            </div>


          </div>
          <div class="betweenCol">


            <div class="input">
              <span>{{ $t('BackBody') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.breastReduction"
                :placeholder="$t('')"
                class="reniaGjoksit1"
              />
            </div>
            <div class="input">
              <span>{{ $t('Shoulders') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.chestDistance"
                :placeholder="$t('')"
                class="distancaGjoksit1"
              />
            </div>
            <div class="input">
              <span>{{ $t('ShouldersSeam') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.sleeveLength"
                :placeholder="$t('')"
                class="distancaGjoksit1"
              />
            </div>
            <div class="input">
              <span>{{ $t('Distance') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.interBust"
                :placeholder="$t('')"
                class="nderGjoks1"
              />
            </div>

            <div class="input">
              <span>{{ $t('ButtocksReduction') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.frontBody"
                :placeholder="$t('')"
                class="trupiPara1"
              />
            </div>
            <div class="input">
              <span>{{ $t('Height') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.length1"
                :placeholder="$t('')"
                class="gjatesia11"
              />
            </div>
            <div class="input">
              <span>{{ $t('3QuartersLength') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.length2"
                :placeholder="$t('')"
                class="gjatesia21"
              />
            </div>
          </div>
          <div class="betweenCol">

            <div class="input">
              <span>{{ $t('ChanelLength') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.length3"
                :placeholder="$t('')"
                class="gjatesia31"
              />
            </div>
            <div class="input">
              <span>{{ $t('MiniLength') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.length4"
                :placeholder="$t('')"
                class="gjatesia41"
              />
            </div>
            <div class="input">
              <span>{{ $t('SleeveCircumference') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.aroundSleeve"
                :placeholder="$t('')"
                class="rrethiMenges1"
              />
            </div>
            <div class="input">
              <span>{{ $t('SleeveLength') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.elbow"
                :placeholder="$t('')"
                class="brryli1"
              />
            </div>
            <div class="input">
              <span>{{ $t('M.3QuartersLength') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.wrist"
                :placeholder="$t('')"
                class="kyçi1"
              />
            </div>
            <div class="input">
              <span>{{ $t('ElbowCircumference') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.aroundNeck"
                :placeholder="$t('')"
                class="rrethiQafes1"
              />
            </div>
            <div class="input">
              <span>{{ $t('WristCircumference') }}</span>
              <b-form-input
                v-model="dress.bodyMeasurements.backBody"
                :placeholder="$t('')"
                class="trupiPrapa1"
              />
            </div>
          </div>
        </div>

      </b-collapse>
    </div>
    <div
      v-if="dress.twentyPrinciples != null && client != 'ValdrinSahiti'"
      class="skica-container"
    >
      <b-collapse
        :id="`collapse-standard`"
        visible
      >
        <div
          class="btn-con"
        >
          <button
            type="button"
            @click="flipBody = !flipBody"
          >
            {{ $t('FlipBody') }}
          </button>
        </div>
        <div
          v-if="flipBody"
          class="front-body"
        >
          <img
            src="../assets/images/front-body.svg"
            alt=""
          >
          <div class="toraks">
            <span class="number">1.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.thorax"
              :placeholder="$t('toraks')"
              class="toraks1"
            />
            <b-form-radio
              v-model="radioStates.thorax"
              :value="true"
              :unchecked-value="false"
              class="pink-radio"
              :disabled="dress.bodyMeasurements.thorax === '' || dress.bodyMeasurements.thorax === null"
            />
          </div>
          <div class="rrethiGjoksit">
            <span class="number">2.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.aroundBust"
              :placeholder="$t('rrethiGjoksit')"
              class="rrethiGjoksit1"
            />
            <b-form-radio
              v-model="radioStates.aroundBust"
              :value="true"
              :unchecked-value="false"
              class="pink-radio"
              :disabled="dress.bodyMeasurements.aroundBust === '' || dress.bodyMeasurements.aroundBust === null"
            />
          </div>
          <div class="rrethiNenGjoksit">
            <span class="number">3.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.aroundUnderBust"
              :placeholder="$t('rrethiNenGjoksit')"
              class="rrethiNenGjoksit1"
            />
            <b-form-radio
              v-model="radioStates.aroundUnderBust"
              :value="true"
              :unchecked-value="false"
              class="pink-radio"
              :disabled="dress.bodyMeasurements.aroundUnderBust === '' || dress.bodyMeasurements.aroundUnderBust === null"
            />
          </div>
          <div class="beli">
            <span class="number">4.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.waist"
              :placeholder="$t('beli')"
              class="beli1"
            />
            <b-form-radio
              v-model="radioStates.waist"
              :value="true"
              :unchecked-value="false"
              class="pink-radio"
              :disabled="dress.bodyMeasurements.waist === '' || dress.bodyMeasurements.waist === null"
            />
          </div>
          <div class="vithet">
            <b-form-radio
              v-model="radioStates.aroundHips"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.aroundHips === '' || dress.bodyMeasurements.aroundHips === null"
            />
            <span class="number">5.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.aroundHips"
              :placeholder="$t('RrethiVitheve')"
              class="vithet1"
            />
          </div>
          <div class="rrethiKembes">
            <b-form-radio
              v-model="radioStates.aroundLeg"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.aroundLeg === '' || dress.bodyMeasurements.aroundLeg === null"
            />
            <span class="number">6.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.aroundLeg"
              :placeholder="$t('rrethiKembes')"
              class="rrethiKembes1"
            />
          </div>
          <div class="rrethiGjurit">
            <b-form-radio
              v-model="radioStates.aroundKnee"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.aroundKnee === '' || dress.bodyMeasurements.aroundKnee === null"
            />
            <span class="number">7.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.aroundKnee"
              :placeholder="$t('rrethiGjurit')"
              class="rrethiGjurit1"
            />
          </div>
          <div class="zoguKembeve">
            <b-form-radio
              v-model="radioStates.ankle"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.ankle === '' || dress.bodyMeasurements.ankle === null"
            />
            <span class="number">8.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.ankle"
              :placeholder="$t('zoguKembeve')"
              class="zoguKembeve1"
            />
          </div>

          <div class="reniaGjoksit">
            <b-form-radio
              v-model="radioStates.breastReduction"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.breastReduction === '' || dress.bodyMeasurements.breastReduction === null"
            />
            <span class="number">9.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.breastReduction"
              :placeholder="$t('reniaGjoksit')"
              class="reniaGjoksit1"
            />
          </div>
          <div class="distancaGjoksit">
            <b-form-radio
              v-model="radioStates.chestDistance"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.chestDistance === '' || dress.bodyMeasurements.chestDistance === null"
            />
            <span class="number">10.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.chestDistance"
              :placeholder="$t('distancaGjoksit')"
              class="distancaGjoksit1"
            />
          </div>
          <div class="nderGjoks">
            <b-form-radio
              v-model="radioStates.interBust"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.interBust === '' || dress.bodyMeasurements.interBust === null"
            />
            <span class="number">11.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.interBust"
              :placeholder="$t('nderGjoks')"
              class="nderGjoks1"
            />
          </div>
          <div class="trupiPara">
            <b-form-radio
              v-model="radioStates.frontBody"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.frontBody === '' || dress.bodyMeasurements.frontBody === null"
            />
            <span class="number">12.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.frontBody"
              :placeholder="$t('trupiPara')"
              class="trupiPara1"
            />
          </div>
          <div class="gjatesia1">
            <span class="number">13.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.length1"
              :placeholder="$t('gjatesia1')"
              class="gjatesia11"
            />
            <b-form-radio
              v-model="radioStates.length1"
              :value="true"
              :unchecked-value="false"
              class="pink-radio"
              :disabled="dress.bodyMeasurements.length1 === '' || dress.bodyMeasurements.length1 === null"
            />
          </div>
          <div class="gjatesia2">
            <span class="number">14.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.length2"
              :placeholder="$t('gjatesia2')"
              class="gjatesia21"
            />
            <b-form-radio
              v-model="radioStates.length2"
              :value="true"
              :unchecked-value="false"
              class="pink-radio"
              :disabled="dress.bodyMeasurements.length2 === '' || dress.bodyMeasurements.length2 === null"
            />
          </div>
          <div class="gjatesia3">
            <span class="number">15.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.length3"
              :placeholder="$t('gjatesia3')"
              class="gjatesia31"
            />
            <b-form-radio
              v-model="radioStates.length3"
              :value="true"
              :unchecked-value="false"
              class="pink-radio"
              :disabled="dress.bodyMeasurements.length3 === '' || dress.bodyMeasurements.length3 === null"
            />
          </div>
          <div class="gjatesia4">
            <span class="number">16.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.length4"
              :placeholder="$t('gjatesia4')"
              class="gjatesia41"
            />
            <b-form-radio
              v-model="radioStates.length4"
              :value="true"
              :unchecked-value="false"
              class="pink-radio"
              :disabled="dress.bodyMeasurements.length4 === '' || dress.bodyMeasurements.length4 === null"
            />
          </div>
        </div>
        <div
          v-if="!flipBody"
          class="back-body"
        >
          <img
            src="../assets/images/back-body.svg"
            alt=""
          >
          <div class="rrethiMenges">
            <b-form-radio
              v-model="radioStates.aroundSleeve"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.aroundSleeve === '' || dress.bodyMeasurements.aroundSleeve === null"
            />
            <span class="number">9.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.aroundSleeve"
              :placeholder="$t('rrethiMenges')"
              class="rrethiMenges1"
            />
          </div>
          <div class="brryli">
            <b-form-radio
              v-model="radioStates.elbow"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.elbow === '' || dress.bodyMeasurements.elbow === null"
            />
            <span class="number">10.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.elbow"
              :placeholder="$t('brryli')"
              class="brryli1"
            />
          </div>
          <div class="kyçi">
            <span class="number">11.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.wrist"
              :placeholder="$t('kyqi')"
              class="kyçi1"
            />
            <b-form-radio
              v-model="radioStates.wrist"
              :value="true"
              :unchecked-value="false"
              class="pink-radio"
              :disabled="dress.bodyMeasurements.wrist === '' || dress.bodyMeasurements.wrist === null"
            />
          </div>
          <div class="rrethiQafes">
            <b-form-radio
              v-model="radioStates.aroundNeck"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.aroundNeck === '' || dress.bodyMeasurements.aroundNeck === null"
            />
            <span class="number">12.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.aroundNeck"
              :placeholder="$t('rrethiQafes')"
              class="rrethiQafes1"
            />
          </div>
          <div class="trupiPrapa">
            <b-form-radio
              v-model="radioStates.backBody"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.backBody === '' || dress.bodyMeasurements.backBody === null"
            />
            <span class="number">16.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.backBody"
              :placeholder="$t('trupiPrapa')"
              class="trupiPrapa1"
            />
          </div>
          <div class="supet">
            <span class="number">17.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.shoulders"
              :placeholder="$t('supet')"
              class="supet1"
            />
            <b-form-radio
              v-model="radioStates.shoulders"
              :value="true"
              :unchecked-value="false"
              class="pink-radio"
              :disabled="dress.bodyMeasurements.shoulders === '' || dress.bodyMeasurements.shoulders === null"
            />
          </div>
          <div class="shpina">
            <span class="number">18.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.back"
              :placeholder="$t('shpina')"
              class="shpina1"
            />
            <b-form-radio
              v-model="radioStates.back"
              :value="true"
              :unchecked-value="false"
              class="pink-radio"
              :disabled="dress.bodyMeasurements.back === '' || dress.bodyMeasurements.back === null"
            />
          </div>
          <div class="reniaVitheve">
            <b-form-radio
              v-model="radioStates.declineOfTheHips"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.declineOfTheHips === '' || dress.bodyMeasurements.declineOfTheHips === null"
            />
            <span class="number">20.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.declineOfTheHips"
              :placeholder="$t('reniaVitheve')"
              class="reniaVitheve1"
            />
          </div>
          <div class="gjatesiaMenges">
            <b-form-radio
              v-model="radioStates.sleeveLength"
              :value="true"
              :unchecked-value="false"
              class="pink-radio right"
              :disabled="dress.bodyMeasurements.sleeveLength === '' || dress.bodyMeasurements.sleeveLength === null"
            />
            <span class="number">21.</span>
            <b-form-input
              v-model="dress.bodyMeasurements.sleeveLength"
              :placeholder="$t('gjatesiaMenges')"
              class="gjatesiaMenges1"
            />
          </div>
        </div>
      </b-collapse>
    </div>
    <div>
      <button
        style="display: inline-block;
          background: #FF274F !important;
          color: #f4f1ed;
          border-color: transparent;
          border-radius: 7px;
          padding: 8px;
          color: white;
          margin-bottom: 5px;
          width: 20%;"
        type="button"
        @click="toggleCollapse"
      >
        <p style="border-radius: 5px; margin-bottom: 0px; color: white;">
          {{ showContent ? $t('HideMaterial') : $t('ShowMaterialVariant') }}
        </p>
      </button>

      <b-collapse
        :id="`lapse-${idx}`"
        :visible="showContent"
      >
        <div
          class="modal-card__wrapper"
          style="align-items: center"
        >
          <div
            v-for="(item, index) in dress.materials.productSingleMaterialVariantDtos"
            :key="index"
            class="modal-card"
          >
            <b-form-group
              id="input-group-3"
              :label="`${$t('SelectMaterialType')}:`"
            >
              <i
                class="modal-card__remove"
                @click="removeMaterialItemFromCD(index)"
              >
                <b-icon-trash />
              </i>
              <div
                class="flex radio-form"
                style="justify-content: space-between;"
              >
                <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px;">
                  <div
                    :id="`one${index}`"
                    class="flex aic"
                    @click="resetMeter(item)"
                  >
                    <input
                      v-model="item.measurementType"
                      type="radio"
                      value="Meter"
                    >
                    <label :for="`one${index}`">{{ $t('Meter') }}</label>
                  </div>
                  <div
                    :id="`one${index}`"
                    class="flex aic"
                    @click="resetPackage(item)"
                  >
                    <input
                      v-model="item.measurementType"
                      type="radio"
                      value="Package"
                    >
                    <label :for="`one${index}`">{{ $t('Package') }}</label>
                  </div>
                  <div
                    :id="`one${index}`"
                    class="flex aic"
                    @click="resetSet(item)"
                  >
                    <input
                      v-model="item.measurementType"
                      type="radio"
                      value="Set"
                    >
                    <label :for="`one${index}`">{{ $t('Set') }}</label>
                  </div>
                  <div
                    :id="`one${index}`"
                    class="flex aic"
                    @click="resetPair(item)"
                  >
                    <input
                      v-model="item.measurementType"
                      type="radio"
                      value="Pair"
                    >
                    <label :for="`one${index}`">{{ $t('Pair') }}</label>
                  </div>
                  <div
                    :id="`one${index}`"
                    class="flex aic"
                    @click="resetVerse(item)"
                  >
                    <input
                      v-model="item.measurementType"
                      type="radio"
                      value="Verse"
                    >
                    <label :for="`one${index}`">{{ $t('Beaded strand') }}</label>
                  </div>
                  <div
                    :id="`two${index}`"
                    class="flex aic"
                    @click="resetPart(item)"
                  >
                    <input
                      v-model="item.measurementType"
                      type="radio"
                      value="Part"
                    >
                    <label :for="`two${index}`">  {{ $t('Part') }}</label>
                  </div>
                </div>
                <td>
                  <img
                    v-if="item.name != null "
                    v-b-tooltip="$t('ClickToViewPhoto')"
                    style="height: 30px; border-radius: 6px; cursor: pointer"
                    src="../assets/images/imageicon.svg"
                    alt=""
                    @click.stop="getAllImages(item.materialVariantId)"
                  >
                </td>
              </div>
            </b-form-group>

            <div class="search-toggle">

              <p>{{ $t('SearchBy') }}</p>
              <div>
                <p
                  :class="{ active2: toggleSearch === 'MaterialName' }"
                  @click="showMaterialName(), toggleSearch = 'MaterialName'"
                >
                  {{ $t('Name') }}
                </p>
                <p
                  :class="{ active2: toggleSearch === 'Description' }"
                  @click="showDescription(), toggleSearch = 'Description'"
                >
                  {{ $t('Description') }}
                </p>
              </div>
            </div>

            <b-form-group
              id="input-select-1"
              label-for="select-1"
            >
              <vue-select
                v-if="item.measurementType === 'Meter' && toggleSearch === 'Description'"
                v-model="item.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByMeter : getFilterVariantsByCodeLike"
                :placeholder="$t('Description')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchMDesc"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageM == 1"
                    @click="prevMeter"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                    @click="nextMeter"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Meter' && toggleSearch === 'MaterialName'"
                v-model="item.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByMeter : getMaterialVariantColorNameLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchM"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageM == 1"
                    @click="prevMeter"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                    @click="nextMeter"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Package' && toggleSearch === 'MaterialName'"
                v-model="item.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPackage : getMaterialVariantColorNameLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchPack"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pagePack == 1"
                    @click="prevPackage"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                    @click="nextPackage"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Package' && toggleSearch === 'Description'"
                v-model="item.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPackage : getFilterVariantsByCodeLike"
                :placeholder="$t('Description')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchPackDesc"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pagePack == 1"
                    @click="prevPackage"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                    @click="nextPackage"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Set' && toggleSearch === 'MaterialName'"
                v-model="item.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVBySet : getMaterialVariantColorNameLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchSet"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageSet == 1"
                    @click="prevSet"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                    @click="nextSet"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Set' && toggleSearch === 'Description'"
                v-model="item.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVBySet : getFilterVariantsByCodeLike"
                :placeholder="$t('Description')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchSetDesc"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageSet == 1"
                    @click="prevSet"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                    @click="nextSet"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Pair' && toggleSearch === 'MaterialName'"
                v-model="item.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPair : getMaterialVariantColorNameLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchPair"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pagePair == 1"
                    @click="prevPair"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                    @click="nextPair"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Pair' && toggleSearch === 'Description'"
                v-model="item.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPair : getFilterVariantsByCodeLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchPairDesc"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pagePair == 1"
                    @click="prevPair"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                    @click="nextPair"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Verse' && toggleSearch === 'MaterialName'"
                v-model="item.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByVerse : getMaterialVariantColorNameLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchVerse"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageVerse == 1"
                    @click="prevVerse"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                    @click="nextVerse"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Verse' && toggleSearch === 'Description'"
                v-model="item.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByVerse : getFilterVariantsByCodeLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchVerseDesc"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageVerse == 1"
                    @click="prevVerse"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                    @click="nextVerse"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Part' && toggleSearch === 'MaterialName'"
                v-model="item.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPart : getMaterialVariantColorNameLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchP"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageP == 1"
                    @click="prevPart"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    type="button"
                    :style="getButtonStylePart"
                    :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                    @click="nextPart"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Part' && toggleSearch === 'Description'"
                v-model="item.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPart : getFilterVariantsByCodeLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchPDesc"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageP == 1"
                    @click="prevPart"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    type="button"
                    :style="getButtonStylePart"
                    :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                    @click="nextPart"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
            </b-form-group>
            <!-- <b-form-group
              v-if="item.name"
              :label="`${$t('Price')}:`"
              style="width: 100%;"
            >
              <b-input
                v-model="item.name.pricePerMeter"
                type="number"
              />
            </b-form-group> -->

            <b-form-group
              v-if="item.measurementType === 'Meter'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="item.length"
                required
                type="number"
                @input="valid(item,index)"
              />
              <p
                v-if="item.length < 0.001"
                style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="item.measurementType === 'Package'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="item.length"
                type="number"
                @input="valid(item,index)"
              />
              <!-- <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ item.name ? `${'Price'}: ${totalPriceCalc(item.name.pricePerMeter, item.length)}€` : null }}
              </p> -->
              <p
                v-if="item.length < 0.0001 "
                style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="item.measurementType === 'Set'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="item.length"
                type="number"
                @input="valid(item,index)"
              />
              <!-- <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ item.name ? `${'Price'}: ${totalPriceCalc(item.name.pricePerMeter, item.length)}€` : null }}
              </p> -->
              <p
                v-if="item.length < 0.0001 "
                style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="item.measurementType === 'Pair'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="item.length"
                type="number"
                @input="valid(item,index)"
              />
              <!-- <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ item.name ? `${'Price'}: ${totalPriceCalc(item.name.pricePerMeter, item.length)}€` : null }}
              </p> -->
              <p
                v-if="item.length < 0.0001 "
                style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="item.measurementType === 'Verse'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="item.length"
                type="number"
                @input="valid(item,index)"
              />
              <!-- <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ item.name ? `${'Price'}: ${totalPriceCalc(item.name.pricePerMeter, item.length)}€` : null }}
              </p> -->
              <p
                v-if="item.length < 0.0001 "
                style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>

            <b-form-group
              v-else
              :label="`${$t('Part')}:`"
            >

              <b-input
                v-model="item.quantity"
                required
                type="number"
                @input="valid(item,index)"
              />
              <p
                v-if="item.quantity < 0.001"
                style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              v-if="item.name ? item.name.materialVariantId === clickedMaterialVariantId : false"
              :label="`${$t('Stock')}:`"
              style="width: 100%;"
            >
              <b-form-input
                v-model="getSavedStock.stockValue"
                type="number"
                disabled
              />
            </b-form-group>
          </div>
          <b-button
            type="button"
            size="sm"
            class="buttonSubmit"
            style="margin: 20px 0;"
            @click="addNewRowForCD()"
          >
            <p style="margin: 0">
              + {{ $t('AddMaterial') }}
            </p>
          </b-button>
        </div>
      </b-collapse>
    </div>
    <div
      class="modal-card__wrapper"
      style="display:flex; flex-direction: column;"
    >
      <div style="display: flex; flex-direction: row; flex-wrap: wrap">
        <div style="display: flex; flex-direction: column;width: 28%;">
          <b-form-group
            :label="`${$t('PickupDate')}:`"
            style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black;"
          >
            <b-input
              v-model="dress.pickUpDate"
              type="date"
            />
          </b-form-group>
          <p
            v-if="!dress.filledPickUpDate"
            style="color:red; margin: 5px; display: inline-flex;"
          >
            {{ $t('PickUpReq') }}
          </p>
        </div>
        <div
          style="display: flex; flex-direction: row; flex-wrap: wrap"
        >
          <div
            v-for="(probeDate, idx) in dress.probeDates"
            :key="idx"
            style="display: flex; flex-direction: column; margin-top: 2px;"
          >
            <b-form-group
              style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-left: 0px;"
            >
              <div style="display: flex; justify-content: space-between;">
                <h1
                  v-if="!editProbeName"
                  style="font-size: 17px; font-weight: 400;"
                >
                  {{ probeDate.probeName }}
                </h1>
                <b-input
                  v-else
                  v-model="probeDate.probeName"
                />
                <div
                  class="inside-actions"
                  @click="editProbeName = !editProbeName"
                >
                  <b-icon-pencil v-if="editProbeName == false" />
                  <b-icon-check2 v-if="editProbeName == true" />
                </div>
              </div>
              <div style="display: flex;">
                <b-input
                  v-model="probeDate.dateOfProbe"
                  type="date"
                />
                <div
                  style="height:35px; width: 40px !important; background-color: white; border-radius: 10px; display: flex; justify-content: center; align-items: center; cursor: pointer; margin-left: 0px;"
                  @click="removeProbeDate(idx)"
                >
                  <b-icon-trash
                    style="width: auto; height: 20px; color: black; "
                  />
                </div>
              </div>
              <b-input
                v-model="probeDate.notes"
                :placeholder="$t('Notes')"
                style="margin-top:5px"
              />
            </b-form-group>
            <p
              v-if="showFittingError"
              style="color: red;margin: 5px;"
            >
              {{ $t('FittingDayReq') }}
            </p>
          </div>
        </div>
        <b-button
          type="button"
          size="sm"
          class="button"
          style="margin-top: 40px; width: 280px"
          @click="addNewDate"
        >
          <p style="margin: 0">
            + {{ $t('Add Fitting Date') }}
          </p>
        </b-button>
      </div>


    </div>
    <div style="display: flex; width: 100%; border: 1px solid lightgray; border-radius: 5px">
      <div style="display: flex; width: 28%; flex-direction: column;">
        <b-form-group
          :label="`${$t('Price')}:`"
          style="display: inline-block; background: white ; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; width: 100%;"
        >
          <div style="display: flex; justify-content: flex-start; align-items: center;flex-direction: column;">

            <div style="display: flex;">
              <b-input
                v-model.number="dress.itemi.price"
                type="number"
                style="width: 90%; margin-right: 5px;"
              />
              <b-form-select
                v-model="dress.itemi.currency "
                dropup
                required
                :options="currencies"
                aria-placeholder="Select order type"
                aria-describedby="input-1-live-feedback"
                style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
              />
            </div>
          </div>
          <p
            v-if="discountType == '%' && (dress.itemi.currency != '$' && dress.itemi.currency != '€' && dress.itemi.currency != 'CHF') "
            style="margin-bottom:0px"
          >
            {{ $t('SelectValute') }}
          </p>
        </b-form-group>
        <b-form-group
          :label="$t('PaidPrice')"
          style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin: 10px 0px 0px 0px; width: 100%;"
        >
          <div style="display: flex; justify-content: flex-start; align-items: center;flex-direction:column; width: 100%">
            <div style="display:flex;">
              <b-input
                v-model.number="dress.paidPrice"
                type="number"
                style="width: 90%; margin-right: 5px;"
              />
              <b-form-select
                v-model="dress.itemi.currency "
                dropup
                required
                :options="currencies"
                aria-placeholder="Select order type"
                aria-describedby="input-1-live-feedback"
                style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
              />
            </div>
            <div style="width: 100%">
              <b-form-group
                :label="`${$t('Notes')}:`"
              >
                <b-input
                  v-model.number="dress.itemi.priceNote"
                  style="width: 100%; margin-right: 5px;"
                />
              </b-form-group>
            </div>
          </div>
          <p
            v-if="discountType == '%' && (dress.itemi.currency != '$' && dress.itemi.currency != '€' && dress.itemi.currency != 'CHF') "
            style="margin-bottom:0px"
          >
            {{ $t('SelectValute') }}
          </p>
        </b-form-group>
        <div style="margin: 5%; display: flex">
          <p class="p-0 m-0 ml-50">
            {{ $t('IncludeTax') }}
            <toggle-button
              :value="includeTax"
              :sync="true"
              color="#242F6E"
              @change="includeTax = !includeTax"
            />
          </p>
          <div
            v-if="includeTax == true"
            style=" margin-left: 10px; margin-bottom: 10px"
          >
            <span>18%</span>
          <!-- <b-form-group
            :label="
              `${$t('TaxRate')}:`"
          >
            <b-input
            v-model.number="dress.itemi.taxRate"
              style="width: 100%;"
            />
          </b-form-group> -->
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; margin-left:0px; justify-content:flex-start; height: 1%;">
        <b-button
          v-if="!showDiscountForm"
          type="button"
          size="sm"
          class="button"
          style="margin: 20px 20px 20px 0px; width: 280px; margin-top: 16.5%"
          @click="showDiscountForm = true"
        >
          <p style="margin: 0">
            + {{ $t('AddDiscount') }}
          </p>
        </b-button>
        <b-form-group
          v-if="showDiscountForm"
          :label="`${$t('Discount')}:`"
          style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; width: 30%; margin-right: 5px;margin-left: 10px;"
        >
          <div style="display: flex;justify-content: flex-start; align-items: center;">
            <b-input
              v-model.number="dress.itemi.discount"
              type="number"
              default="0"
              style="width: 55%; ;margin-right: 5px;"
            />
            <b-form-select
              v-model="discountType"
              style="width: 50%; margin-right: 5px;height:38px; border-radius: 0.25rem; border: 1px solid #EAECF0"
            >
              <option value="$">
                $
              </option>
              <option value="€">
                €
              </option>
              <option value="CHF">
                CHF
              </option>
              <option value="%">
                %
              </option>
            </b-form-select>
            <div>
              <b-icon-trash
                type="button"
                size="sm"
                style="background:white;color: black; height: 36px; width: 30px; padding: 5px; border-radius: 0.25rem;"
                @click="showDiscountForm = false,resetDiscountedPrice() "
              />
            </div>
          </div>
        </b-form-group>
        <b-form-group
          v-if="showDiscountForm"
          :label="`${$t('PriceDiscount')}:`"
          style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; width: 30%;"
        >
          <div style="display:flex">
            <b-input
              v-model.number="discountedPrice"
              disabled
              type="number"
              style="width: 90%; margin-right: 5px;"
            />
            <b-form-select
              v-model="dress.itemi.currency "
              dropup
              required
              :options="currencies"
              aria-placeholder="Select order type"
              aria-describedby="input-1-live-feedback"
              style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
            />
          </div>
          <p
            v-if="discountType == '%' && (dress.itemi.currency != '$' && dress.itemi.currency != '€' && dress.itemi.currency != 'CHF') "
            style="margin-bottom:0px; border: 1px solid #EAECF0"
          >
            {{ $t('SelectValute') }}
          </p>
        </b-form-group>
      </div>
    </div>
    <vue-easy-lightbox
      :esc-disabled="false"
      :visible="image.visible"
      :imgs="image.imgs"
      :index="image.index"
      @hide="handleHide"
    />
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import {
  required,
} from 'vuelidate/lib/validators';
import VueEasyLightbox from 'vue-easy-lightbox'
import moment from 'moment'
import { client } from '../domainConfig'

export default {
  components: {
    VueEasyLightbox,

  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['dress', 'submitClicked', 'articleid', 'showFittingError', 'clientId', 'showMeasurements', 'body'],
  data() {
    return {
      toggleSearch: 'MaterialName',
      clickedMaterialVariantId: null,
      includeTax: false,
      taxRate: 0,
      selectedMaterialVariantPackage: '',
      selectedMaterialVariant: '',
      selectedMaterialVariantPart: '',
      selectedMaterialVariantSet: '',
      selectedMaterialVariantPair: '',
      selectedMaterialVariantVerse: '',
      currencies: ['€', '$', 'CHF'],
      discountType: '€',
      showDiscountForm: false,
      isFalse: false,
      client: client.clientName,
      articleNumberId: this.articleid,
      index: 1,
      pageM: 1,
      pageP: 1,
      pagePack: 1,
      pageSet: 1,
      pagePair: 1,
      pageVerse: 1,
      flipBody: true,
      idx: 0,
      isUpdatingDress: false,
      image: {
        zoom: 1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, //
      },
      pageSize: 15,
      showContent: false,
      editProbeName: false,
      editArticleNumber: false,
      radioStates: {
        thorax: false,
        aroundBust: false,
        aroundUnderBust: false,
        waist: false,
        aroundHips: false,
        aroundLeg: false,
        aroundKnee: false,
        ankle: false,
        breastReduction: false,
        chestDistance: false,
        interBust: false,
        frontBody: false,
        length1: false,
        length2: false,
        length3: false,
        length4: false,
        aroundSleeve: false,
        elbow: false,
        wrist: false,
        aroundNeck: false,
        backBody: false,
        shoulders: false,
        back: false,
        declineOfTheHips: false,
        sleeveLength: false,
      },
    }
  },
  validations: {
    item: {
      length: {
        required,
      },
      quantity: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(['getMaterialVariantColorNameLike', 'getSavedStock', 'getTotalItemsPages', 'getImagesProduct', 'getEditOrder', 'getMeasermentsById', 'getEditOrderItemMaterialVariant', 'getCurrentPageForMVP', 'getMaterialsVByMeter',
      'getMaterialsVByPart', 'getFilteredMV', 'getTotalPagesForMVP',
      'getTotalPagesForMVM', 'getCurrentPageForMVM', 'getCurrentPageForMVP',
      'getMeasermentsById', 'getMaterialsVByPackage', 'getMaterialsVBySet',
      'getMaterialsVByVerse', 'getMaterialsVByPair', 'getFilterVariantsByCodeLike']),
    discountedPrice: {
      get() {
        if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
          return this.dress.itemi.price - this.dress.itemi.discount;
        } if (this.discountType === '%') {
          if (this.dress.itemi.price === 0) {
            return 0; // Avoid division by zero
          }
          const percentageDiscount = (this.dress.itemi.discount / 100) * this.dress.itemi.price;
          return this.dress.itemi.price - percentageDiscount;
        }

        // Default to 0 when no discount type is selected
        return 0;
      },
      set(value) {
        // You can add validation here if needed
        if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
          this.dress.itemi.discount = this.dress.itemi.price - value;
        } else if (this.discountType === '%') {
          if (this.dress.itemi.price === 0) {
            this.dress.itemi.discount = 0; // Avoid division by zero
          } else {
            const percentageDiscount = (this.dress.itemi.price - value) / (this.dress.itemi.price * 100);
            this.dress.itemi.discount = percentageDiscount;
          }
        }
      },
    },
    getButtonStyle() {
      if (this.selectedMaterialVariant !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVM
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    getButtonStylePart() {
      if (this.selectedMaterialVariantPart !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVP
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },

  },
  watch: {
    // eslint-disable-next-line func-names
    'dress.itemi.price': function (newPrice) {
      if (newPrice < this.dress.itemi.discount) {
        this.dress.itemi.discount = 0;
      }
    },
    // eslint-disable-next-line func-names
    'dress.itemi.discount': function (newDiscount) {
      if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
        if (newDiscount > this.dress.itemi.price && newDiscount > this.discountedPrice) {
          this.dress.itemi.discount = 0;
        }
      } else if (this.discountType === '%') {
        if (newDiscount > 100) {
          this.dress.itemi.discount = 0;
        }
      }
    },
    // eslint-disable-next-line func-names
    'body.bodyMeasurementDto': function (value) {
      this.dress.bodyMeasurements.thorax = value.thorax
      this.dress.bodyMeasurements.aroundBust = value.aroundBust
      this.dress.bodyMeasurements.aroundUnderBust = value.aroundUnderBust
      this.dress.bodyMeasurements.waist = value.waist
      this.dress.bodyMeasurements.aroundHips = value.aroundHips
      this.dress.bodyMeasurements.aroundLeg = value.aroundLeg
      this.dress.bodyMeasurements.aroundKnee = value.aroundKnee
      this.dress.bodyMeasurements.aroundNeck = value.aroundNeck
      this.dress.bodyMeasurements.aroundSleeve = value.aroundSleeve
      this.dress.bodyMeasurements.ankle = value.ankle
      this.dress.bodyMeasurements.breastReduction = value.breastReduction
      this.dress.bodyMeasurements.chestDistance = value.chestDistance
      this.dress.bodyMeasurements.interBust = value.interBust
      this.dress.bodyMeasurements.frontBody = value.frontBody
      this.dress.bodyMeasurements.length1 = value.length1
      this.dress.bodyMeasurements.length2 = value.length2
      this.dress.bodyMeasurements.length3 = value.length3
      this.dress.bodyMeasurements.length4 = value.length4
      this.dress.bodyMeasurements.elbow = value.elbow
      this.dress.bodyMeasurements.wrist = value.wrist
      this.dress.bodyMeasurements.back = value.back
      this.dress.bodyMeasurements.backBody = value.backBody
      this.dress.bodyMeasurements.shoulders = value.shoulders
      this.dress.bodyMeasurements.declineOfTheHips = value.declineOfTheHips
      this.dress.bodyMeasurements.sleeveLength = value.sleeveLength
      // else {
      //   this.dress.bodyMeasurements.thorax = null
      // this.dress.bodyMeasurements.aroundBust = null
      // this.dress.bodyMeasurements.aroundUnderBust = null
      // this.dress.bodyMeasurements.waist = null
      // this.dress.bodyMeasurements.aroundHips = null
      // this.dress.bodyMeasurements.aroundLeg = null
      // this.dress.bodyMeasurements.aroundKnee = null
      // this.dress.bodyMeasurements.aroundNeck = null
      // this.dress.bodyMeasurements.aroundSleeve = null
      // this.dress.bodyMeasurements.ankle = null
      // this.dress.bodyMeasurements.breastReduction = null
      // this.dress.bodyMeasurements.chestDistance = null
      // this.dress.bodyMeasurements.interBust = null
      // this.dress.bodyMeasurements.frontBody = null
      // this.dress.bodyMeasurements.length1 = null
      // this.dress.bodyMeasurements.length2 = null
      // this.dress.bodyMeasurements.length3 = null
      // this.dress.bodyMeasurements.length4 = null
      // this.dress.bodyMeasurements.elbow = null
      // this.dress.bodyMeasurements.wrist = null
      // this.dress.bodyMeasurements.back = null
      // this.dress.bodyMeasurements.backBody = null
      // this.dress.bodyMeasurements.shoulders = null
      // this.dress.bodyMeasurements.declineOfTheHips = null
      // this.dress.bodyMeasurements.sleeveLength = null
      // }
    },
    includeTax(newIncludeTax) {
      this.taxRate = newIncludeTax ? 18 : 0;
      this.$emit('taxRatee', this.taxRate);
    },
    discountedPrice(newDiscountedPrice) {
      if (newDiscountedPrice > this.dress.itemi.price) {
        this.dress.itemi.price = newDiscountedPrice;
        this.dress.itemi.discount = 0;
      }
    },
    showDiscountForm(newShowDiscountFormValue) {
      // if (newShowDiscountFormValue) {
      //   this.dress.itemi.discount = 0;
      // }
      this.$emit('DiscountValue', newShowDiscountFormValue)
    },
    dress: {
      handler(newValue) {
        if (this.submitClicked == true) {
          if (newValue.pickUpDate == '') {
            this.dress.filledPickUpDate = false
          } else {
            this.dress.filledPickUpDate = true
          }
        }
        if (newValue.materials && newValue.materials.productSingleMaterialVariantDtos) {
          for (let i = 0; i < newValue.materials.productSingleMaterialVariantDtos.length; i++) {
            const materialVariant = newValue.materials.productSingleMaterialVariantDtos[i];
            if (materialVariant.name && materialVariant.name.materialVariantId) {
              materialVariant.materialVariantId = materialVariant.name.materialVariantId;
            }
            if (materialVariant.name && materialVariant.name.materialVariantName) {
              materialVariant.name = materialVariant.name.materialVariantName;
            }
          }
        }
        if (newValue.itemi) {
          // if (newValue.itemi.price === undefined) {
          //   this.$set(newValue.itemi, 'price', 0);
          // }
          if (newValue.itemi.discount === undefined || newValue.itemi.discount === '' || newValue.itemi.discount === null) {
            this.$set(newValue.itemi, 'discount', 0);
          }
        }
      },
      deep: true,
      immediate: true,
    },
    getEditOrderItemMaterialVariant() {
      this.fillForm()
    },
    discountType(value) {
      if (value === '$' || value === '€' || value === 'CHF') {
        this.dress.itemi.currency = value
      } else if (value === '%') {
        this.dress.itemi.currency = '€'
      }
      this.dress.itemi.discount = 0
      this.$emit('discount-type', value)
    },
    // eslint-disable-next-line func-names
    'dress.itemi.currency': function (newCurrency) {
      if ((newCurrency === '$' || newCurrency === '€' || newCurrency === 'CHF') && this.discountType != '%') {
        this.discountType = newCurrency
      }
    },
    // eslint-disable-next-line
   'dress.bodyMeasurements.thorax': function (newVal) {
      this.radioStates.thorax = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.aroundBust': function (newVal) {
      this.radioStates.aroundBust = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.aroundUnderBust': function (newVal) {
      this.radioStates.aroundUnderBust = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.waist': function (newVal) {
      this.radioStates.waist = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.aroundHips': function (newVal) {
      this.radioStates.aroundHips = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.aroundLeg': function (newVal) {
      this.radioStates.aroundLeg = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.aroundKnee': function (newVal) {
      this.radioStates.aroundKnee = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.ankle': function (newVal) {
      this.radioStates.ankle = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.breastReduction': function (newVal) {
      this.radioStates.breastReduction = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.chestDistance': function (newVal) {
      this.radioStates.chestDistance = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.frontBody': function (newVal) {
      this.radioStates.frontBody = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.length1': function (newVal) {
      this.radioStates.length1 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.length2': function (newVal) {
      this.radioStates.length2 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.length3': function (newVal) {
      this.radioStates.length3 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.length4': function (newVal) {
      this.radioStates.length4 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.aroundSleeve': function (newVal) {
      this.radioStates.aroundSleeve = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.elbow': function (newVal) {
      this.radioStates.elbow = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.wrist': function (newVal) {
      this.radioStates.wrist = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.aroundNeck': function (newVal) {
      this.radioStates.aroundNeck = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.backBody': function (newVal) {
      this.radioStates.backBody = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.shoulders': function (newVal) {
      this.radioStates.shoulders = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.back': function (newVal) {
      this.radioStates.back = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.declineOfTheHips': function (newVal) {
      this.radioStates.declineOfTheHips = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.bodyMeasurements.sleeveLength': function (newVal) {
      this.radioStates.sleeveLength = newVal !== '' && newVal !== null;
    },
  },
  mounted() {
    if (this.dress.itemi.discount > 0) {
      this.showDiscountForm = true
    }
    this.dress.itemi.currency = this.discountType;

    this.dress.bodyMeasurements.thorax = this.body.bodyMeasurementDto.thorax
    this.dress.bodyMeasurements.aroundBust = this.body.bodyMeasurementDto.aroundBust
    this.dress.bodyMeasurements.aroundUnderBust = this.body.bodyMeasurementDto.aroundUnderBust
    this.dress.bodyMeasurements.waist = this.body.bodyMeasurementDto.waist
    this.dress.bodyMeasurements.aroundHips = this.body.bodyMeasurementDto.aroundHips
    this.dress.bodyMeasurements.aroundLeg = this.body.bodyMeasurementDto.aroundLeg
    this.dress.bodyMeasurements.aroundKnee = this.body.bodyMeasurementDto.aroundKnee
    this.dress.bodyMeasurements.aroundNeck = this.body.bodyMeasurementDto.aroundNeck
    this.dress.bodyMeasurements.aroundSleeve = this.body.bodyMeasurementDto.aroundSleeve
    this.dress.bodyMeasurements.ankle = this.body.bodyMeasurementDto.ankle
    this.dress.bodyMeasurements.breastReduction = this.body.bodyMeasurementDto.breastReduction
    this.dress.bodyMeasurements.chestDistance = this.body.bodyMeasurementDto.chestDistance
    this.dress.bodyMeasurements.interBust = this.body.bodyMeasurementDto.interBust
    this.dress.bodyMeasurements.frontBody = this.body.bodyMeasurementDto.frontBody
    this.dress.bodyMeasurements.length1 = this.body.bodyMeasurementDto.length1
    this.dress.bodyMeasurements.length2 = this.body.bodyMeasurementDto.length2
    this.dress.bodyMeasurements.length3 = this.body.bodyMeasurementDto.length3
    this.dress.bodyMeasurements.length4 = this.body.bodyMeasurementDto.length4
    this.dress.bodyMeasurements.elbow = this.body.bodyMeasurementDto.elbow
    this.dress.bodyMeasurements.wrist = this.body.bodyMeasurementDto.wrist
    this.dress.bodyMeasurements.back = this.body.bodyMeasurementDto.back
    this.dress.bodyMeasurements.backBody = this.body.bodyMeasurementDto.backBody
    this.dress.bodyMeasurements.shoulders = this.body.bodyMeasurementDto.shoulders
    this.dress.bodyMeasurements.declineOfTheHips = this.body.bodyMeasurementDto.declineOfTheHips
    this.dress.bodyMeasurements.sleeveLength = this.body.bodyMeasurementDto.sleeveLength
  },
  methods: {
    ...mapActions([
      'filterVariantByColor',
      'resetAllFiltered',
      'getImagesByMaterialVariantId',
      'getMaterialVariantsToMDCPagination',
      'edit_OrderItem',
      'getMaterialVariantsToMDCPagination',
      'testResetMV',
      'loadMaterialVaraintsCategoryNameLike',
      'currentStockByVariant',
      'filterVariantByCode']),
    showMaterialName() {
      this.toggleSearch = 'MaterialName'
    },
    showDescription() {
      this.toggleSearch = 'Description'
    },
    currentStock(value) {
      this.clickedMaterialVariantId = value
      this.currentStockByVariant(value)
    },
    addNewRowForCD() {
      if (this.dress.materials && this.dress.materials.productSingleMaterialVariantDtos) {
        this.dress.materials.productSingleMaterialVariantDtos.push({
          length: 0,
          materialVariantId: null,
          measurementType: 'Meter',
          name: null,
          quantity: 0,
        });
      } else {
        this.dress.materials = {
          created: moment().format('YYYY-MM-DD'),
          totalCost: 0,
          estimattedProductionJson: null,
          productSingleMaterialVariantDtos: [
            {
              length: 0,
              materialVariantId: null,
              measurementType: 'Meter',
              name: null,
              quantity: 0,
            },
          ],
        };
      }
    },
    removeProbeDate(index) {
      this.dress.probeDates.splice(index, 1);
    },
    resetDiscountedPrice() {
      this.discountedPrice = this.dress.itemi.price;
      this.dress.itemi.discount = 0
    },
    removeStandardDress(key) {
      this.$emit('sendIndexStandard', key)
    },
    toggleCollapse() {
      this.showContent = !this.showContent;
    },
    valid(item) {
      // if (item < 0.001) {
      //   this.dress.showLengthError = true;
      // } else {
      //   this.dress.showLengthError = false;
      // }
      console.log(item)
      if (item.measurementType == 'Meter') {
        item.quantity = 0
        if (item.length < 0.001) {
          this.dress.showLengthError = true;
        } else {
          this.dress.showLengthError = false;
        }
      } else if (item.measurementType == 'Parts') {
        item.length = 0
        if (item.quantity < 0.001) {
          this.dress.showLengthError = true;
        } else {
          this.dress.showLengthError = false;
        }
      }
    },
    updateSelectedValue() {
      this.$emit('selected-value-change', this.idx, this.selectedValue);
    },
    async getAllImages(item) {
      this.$store.commit('SET_IMAGES_PRODUCTION_MANAGEMENT', [{
        fileDataId: 'aaf153a4-be48-ee11-abf3-124abb807f69',
        mainDressColorId: 'e2b146ea-a7e9-4584-ee19-08dba4a55447',
        url: '',
        fileName: '1693569414176-createordermodal.png',
      }]);
      try {
        // this.changeLoadingtoTrue(false)

        await this.getImagesByMaterialVariantId(item).then((res) => {
          console.log(res);
          // this.changeLoadingtoTrue(true)
        })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
      this.image.imgs = []
      for (let i = 0; i < this.getImagesProduct.length; i++) {
        const image = this.getImagesProduct[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          materialVariantPhotoId: image.materialVariantPhotoId,
          fileName: image.fileName,
          fileType: image.fileType,
        })
      }
      this.show()
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
    addNewDate() {
      this.dress.probeDates.push({
        probeName: `${this.$t('FitDate')} ${this.dress.probeDates.length + 1}`,
        dateOfProbe: '',
        notes: null,
      });
    },
    resetMeter(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
    },
    resetPart(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
    },
    resetPackage(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Package',
      })
    },
    resetSet(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Set',
      })
    },
    resetPair(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Pair',
      })
    },
    resetVerse(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Verse',
      })
    },
    NewRowForCD() {
      this.dress.materials.productSingleMaterialVariantDtos.push({
        length: 0,
        materialVariantId: null,
        measurementType: 'Meter',
        name: null,
        quantity: 0,
      });
    },
    fillForm() {
      this.dress.materials.productSingleMaterialVariantDtos = []
      for (let i = 0; i < this.getEditOrderItemMaterialVariant.length; i++) {
        this.dress.materials.productSingleMaterialVariantDtos.push(this.getEditOrderItemMaterialVariant[i])
      }
    },
    removeMaterialItemFromCD(index) {
      this.dress.materials.productSingleMaterialVariantDtos.splice(index, 1)
    },
    meter() {
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
    },
    part() {
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
    },
    async getMVbyMeter(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Meter',
      });
    },
    async getMVbyPackage(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Package',
      });
    },
    async getMVbySet(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Set',
      });
    },
    async getMVbyVerse(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Verse',
      });
    },
    async getMVbyPair(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Pair',
      });
    },
    async getMVbyPart(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Part',
      });
    },
    async onSearchM(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariant = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchMDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariant = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPack(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPackDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchSet(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchSetDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPair(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPairDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchVerse(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchVerseDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          })
        }
      }, 500);
    },

    async onSearchP(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchPDesc(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          })
        }
      }, 500);
    },
    nextMeter() {
      if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant == '') {
        this.pageM += 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant != '') {
        this.pageM += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    prevMeter() {
      if (this.pageM > 1 && this.selectedMaterialVariant == '') {
        this.pageM -= 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM > 1 && this.selectedMaterialVariant != '') {
        this.pageM -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    nextPackage() {
      if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage == '') {
        this.pagePackage += 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage != '') {
        this.pagePackage += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    prevPackage() {
      if (this.pagePackage > 1 && this.selectedMaterialVariantPackage == '') {
        this.pagePackage -= 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage > 1 && this.selectedMaterialVariantPackage != '') {
        this.pagePackage -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    nextSet() {
      if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet == '') {
        this.pageSet += 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet != '') {
        this.pageSet += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    prevSet() {
      if (this.pageSet > 1 && this.selectedMaterialVariantSet == '') {
        this.pageSet -= 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet > 1 && this.selectedMaterialVariantSet != '') {
        this.pageSet -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    nextVerse() {
      if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse == '') {
        this.pageVerse += 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse != '') {
        this.pageVerse += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    prevVerse() {
      if (this.pageVerse > 1 && this.selectedMaterialVariantVerse == '') {
        this.pageVerse -= 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse > 1 && this.selectedMaterialVariantVerse != '') {
        this.pageVerse -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    nextPair() {
      if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair == '') {
        this.pagePair += 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair != '') {
        this.pagePair += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    prevPair() {
      if (this.pagePair > 1 && this.selectedMaterialVariantPair == '') {
        this.pagePair -= 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair > 1 && this.selectedMaterialVariantPair != '') {
        this.pagePair -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    nextPart() {
      if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart == '') {
        this.pageP += 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart != '') {
        this.pageP += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    prevPart() {
      if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "./../assets//scss/variables";

.pink-radio {
  margin-left: 6px;
  margin-top: 10px;
}
.active2 {
  font-weight: bold;
  color: $base-color;
}
.search-toggle {
  margin: 5px 0px;
}

.right {
  margin: 10px 6px 0px 0px !important;
}
.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 0px 4px;
  border-radius: 7px;
}
.buttoni:hover {
  background-color: #FF274F;
  border: transparent;
}
.number {
    margin-top: 8px;
    padding-right: 5px;
  }
.front-body {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 20px;
  img {
    width: 100%;
  }
  .betweenCol{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .input{
    max-width: 100%;
    height: 51px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .toraks {
    display: flex;
    position: absolute;
    top: 80px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiGjoksit {
    display: flex;
    position: absolute;
    top: 125px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiNenGjoksit {
    display: flex;
    position: absolute;
    top: 170px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .beli {
    display: flex;
    position: absolute;
    top: 215px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .vithet {
    display: flex;
    position: absolute;
    top: 263px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiKembes {
    display: flex;
    position: absolute;
    bottom: 222px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiGjurit {
    display: flex;
    position: absolute;
    bottom: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .zoguKembeve {
    display: flex;
    position: absolute;
    bottom: 48px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .reniaGjoksit {
    display: flex;
    position: absolute;
    top: 65px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .nderGjoks {
    display: flex;
    position: absolute;
    top: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .trupiPara {
    display: flex;
    position: absolute;
    top: 200px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .distancaGjoksit {
    display: flex;
    position: absolute;
    top: 110px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesia1 {
    display: flex;
    position: absolute;
    bottom: 240px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesia2 {
    display: flex;
    position: absolute;
    bottom: 145px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesia3 {
    display: flex;
    position: absolute;
    bottom: 80px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesia4 {
    display: flex;
    position: absolute;
    bottom: 20px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
}
.back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    display: flex;
    position: absolute;
    top: 122px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .brryli {
    display: flex;
    position: absolute;
    top: 166px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .kyçi {
    display: flex;
    position: absolute;
    top: 192px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiQafes {
    display: flex;
    position: absolute;
    top: 30px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .trupiPrapa {
    display: flex;
    position: absolute;
    top: 211px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .supet {
    display: flex;
    position: absolute;
    top: 78px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .shpina {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .reniaVitheve {
    display: flex;
    position: absolute;
    bottom: 253px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesiaMenges {
    display: flex;
    position: absolute;
    top: 77px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
}
.inside-actions:hover {
  background-color: #FF274F;
  color: #fff;
}
.standard-order-con {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
}
.headline {
  padding: 5px 10px;
  background-color: #FF274F;
  color: $digit-white;
  border-radius: 8px;
}
</style>
